import React from 'react';
import {
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
   Checkbox,
} from '@material-tailwind/react';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../contexts/AuthProvider';
import { useServices } from '../services/hooks/useServices';
import { useEmployeeService } from '../services/hooks/useEmployeeService2';

import {
   ButtonComponent,
   CheckBoxComponent,
} from '@syncfusion/ej2-react-buttons';
const EditEmployeeService = ({
   open,
   onClose,
   employee,
   onUpdate,
}) => {
   const { userInfo } = useAuth();
   const { isLoading, data: services } = useServices(userInfo.storeId);
   const { isLoading: isLoading2, data: employee_services } = useEmployeeService(employee.id);

   
   
   const {
      handleSubmit,
      control,
      watch,
      formState: { errors },
   } = useForm();

   if (isLoading || isLoading2) return <div>Loading...</div>;
   function check(service_id) {
      for (let i = 0; i < employee_services.length; i++) {
         if (employee_services[i].service_id === service_id) {
            return true;
         }
      }
      return false;
   }
   const onSubmit = (data) => {
      onUpdate(data);
   };

   return (
      <Dialog open={open} size={'md'}>
         <DialogHeader>Edit Services</DialogHeader>
         <DialogBody divider>
            <form onSubmit={handleSubmit(onSubmit)}>
               {services?.map((service) => (
                  <section key={service.name}>
                     <Controller
                        name={service.id+''}
                        id={service.id}
                        control={control}
                        defaultValue={check(service.id)}
                        render={({ field }) => (
                           <CheckBoxComponent
                              label={service.name}
                              onChange={(e) => field.onChange(e.target.checked)}
                              checked={field.value}
                           />
                        )}
                     />
                  </section>
               ))}
               <div style={{ textAlign: 'center' }} className='p3 m-5'>
                  <ButtonComponent onClick={onClose} cssClass='e-primary'>Cancel</ButtonComponent>
                  <ButtonComponent type='submit' cssClass='e-success'>
                     Save
                  </ButtonComponent>
               </div>
            </form>
         </DialogBody>
         <DialogFooter>
            <div></div>
         </DialogFooter>
      </Dialog>
   );
};

export default EditEmployeeService;

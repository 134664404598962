import React from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Page,
   Selection,
   Inject,
   Edit,
   Toolbar,
   Sort,
   Filter,
   Search,
} from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Header } from '../components';
import { useAddService } from '../services/hooks/useAddService';

const Service = () => {
   const { userInfo } = useAuth();
   const [showDialog, setShowDialog] = React.useState(false);
   const [name, setName] = React.useState('');
   const [price, setPrice] = React.useState(0);
   const [duration, setDuration] = React.useState(0);
   const [categoryId, setCategoryId] = React.useState(1);
   const [onList, setOnList] = React.useState(false);
   const [onWeb, setOnWeb] = React.useState(false);
   const [numOfCustomer, setNumOfCustomer] = React.useState(1);
   const [error, setError] = React.useState('');
   const { isAddingService, addService } = useAddService();

   const toolbarOptions = ['Edit', 'Update', 'Cancel'];
   const editSettings = {
      allowEditing: true,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: 'Top',
   };
   let categoryData = [
      { id: 1, name: 'Massage' },
      { id: 2, name: 'Facial' },
      { id: 3, name: 'Reflexology' },
   ];
   let fields = { text: 'name', value: 'id' };
   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl:
         process.env.REACT_APP_API_SERVER +
         `/api/service/update?store_id=${userInfo.storeId}`,
      url:
         process.env.REACT_APP_API_SERVER +
         `/api/service?store_id=${userInfo.storeId}`,
      //  headers: [AuthHeader()],
   });

   function handleClick() {
      setShowDialog(true);
   }
   function handleCloseDialog() {
      setName('');
      setPrice(0);
      setDuration(0);
      setCategoryId(1);
      setOnList(true);
      setOnWeb(true);
      setNumOfCustomer(1);
      setShowDialog(false);
      setError('');
   }

   function handleAddService() {
      setError('');
      let value = {
         name: name,
         is_visible: onList,
         price: price,
         duration: duration,
         category_id: categoryId,
         on_web: onWeb,
         num_of_customer: numOfCustomer,
         store_id: userInfo.storeId,
      };
      addService(value, {
         onSuccess: () => {
            handleCloseDialog();
         },
         onError: (error) => {
            setError(error.message);
         },
      });

   }
   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header category='Page' title='服务项目' />
         <div id='target' className='col-lg-12 target-element mt-20'></div>
         <div>
            <button
               className='bg-grey-light hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center'
               onClick={handleClick}
            >
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#000000'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
               >
                  <line x1='12' y1='5' x2='12' y2='19'></line>
                  <line x1='5' y1='12' x2='19' y2='12'></line>
               </svg>
               <span> &nbsp;添加服务</span>
            </button>
         </div>
         <div className='w-3/4 mt-10'>
            <GridComponent
               dataSource={dataManager}
               editSettings={editSettings}
               toolbar={toolbarOptions}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field='id'
                     headerText='ID'
                     width='70'
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='name'
                     headerText='Name'
                     width='100'
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field='price'
                     headerText='Price'
                     width='100'
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field='duration'
                     headerText='Duration'
                     width='100'
                     allowEditing={true}
                  />
                   <ColumnDirective
                     field='num_of_customer'
                     headerText='Customer'
                     width='50'
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field='is_visible'
                     headerText='On List'
                     width='100'
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field='on_web'
                     headerText='On Web'
                     width='100'
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field='category_name'
                     headerText='Category'
                     width='100'
                     allowEditing={false}
                  />
               </ColumnsDirective>
               <Inject services={[Toolbar, Edit]} />
            </GridComponent>
         </div>
         <DialogComponent
            id='dialog'
            header='添加服务'
            target='#target'
            width={'520px'}
            showCloseIcon={true}
            visible={showDialog}
            close={handleCloseDialog}
         >
            <form className='w-full max-w-sm'>
               <div className='md:flex md:items-center mb-6'>
                  <div className='md:w-1/3'>
                     <label
                        className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                        htmlFor='inline-full-name'
                     >
                        Name
                     </label>
                  </div>
                  <div className='md:w-2/3'>
                     <input
                        className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                        id='inline-full-name'
                        type='text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                     />
                  </div>
               </div>
               <div className='md:flex md:items-center mb-6'>
                  <div className='md:w-1/3'>
                     <label
                        className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                        for='inline-full-name'
                     >
                        Duration:
                     </label>
                  </div>
                  <div className='md:w-2/3'>
                     <input
                        className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                        id='inline-full-name'
                        type='text'
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                     />
                  </div>
               </div>
               <div className='md:flex md:items-center mb-6'>
                  <div className='md:w-1/3'>
                     <label
                        className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                        for='inline-full-name'
                     >
                        Price
                     </label>
                  </div>
                  <div className='md:w-2/3'>
                     <input
                        className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                        id='inline-full-name'
                        type='text'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                     />
                  </div>
               </div>
               <div className='md:flex md:items-center mb-6'>
                  <div className='md:w-1/3'>
                     <label
                        className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                        for='inline-full-name'
                     >
                        Num of Customer
                     </label>
                  </div>
                  <div className='md:w-2/3'>
                     <input
                        className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500'
                        id='inline-full-name'
                        type='text'
                        value={numOfCustomer}
                        onChange={(e) => setNumOfCustomer(e.target.value)}
                     />
                  </div>
               </div>
               <div className='md:flex md:items-center mb-6'>
                  <div className='md:w-1/3'>
                     <label
                        className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                        for='inline-full-name'
                     >
                        Category
                     </label>
                  </div>
                  <div className='md:w-2/3'>
                     <DropDownListComponent
                        id='category'
                        dataSource={categoryData}
                        fields={fields}
                        placeholder='Select a category'
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.value)}
                        popupHeight='220px'
                     />
                  </div>
               </div>

               <div className='md:flex md:items-center mb-6'>
                  <div className='md:w-1/3'></div>
                  <label className='md:w-2/3 block text-gray-500 font-bold'>
                     <input
                        className='mr-2 leading-tight'
                        type='checkbox'
                        value={onList}
                        onChange={(e) => setOnList(e.target.value)}
                     />
                     <span className='text-sm'>On List</span>
                  </label>
               </div>
               <div className='md:flex md:items-center mb-6'>
                  <div className='md:w-1/3'></div>
                  <label className='md:w-2/3 block text-gray-500 font-bold'>
                     <input
                        className='mr-2 leading-tight'
                        type='checkbox'
                        value={onWeb}
                        onChange={(e) => setOnWeb(e.target.value)}
                     />
                     <span className='text-sm'>On Web</span>
                  </label>
               </div>
               <div>
                  {error && (
                     <div
                        className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                        role='alert'
                     >
                        <strong className='font-bold'>{error}</strong>
                     </div>
                  )}
               </div>
               <div className='md:flex md:items-center'>
                  <div className='md:w-1/3'></div>
                  <div className='md:w-2/3'>
                     <button
                        className='shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded'
                        type='button'
                        onClick={handleAddService}
                     >
                        Add Service
                     </button>
                  </div>
               </div>
            </form>
         </DialogComponent>
      </div>
   );
};

export default Service;

import React, { useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiShoppingCart } from 'react-icons/fi';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import avatar from '../data/avatar.jpg';
import { Cart, Chat, Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { useCustomer } from '../services/hooks/useCustomer2';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { AddCustomerDialog } from '../components';

import toast from 'react-hot-toast';
import { ToasterNotification } from '../components';
import { useAddCustomer } from '../services/hooks/useAddCustomer2';
import { useQueryClient } from '@tanstack/react-query';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
   <TooltipComponent content={title} position='BottomCenter'>
      <button
         type='button'
         onClick={() => customFunc()}
         style={{ color }}
         className='relative text-xl rounded-full p-3 hover:bg-light-gray'
      >
         <span
            style={{ background: dotColor }}
            className='absolute inline-flex rounded-full h-2 w-2 right-2 top-2'
         />
         {icon}
      </button>
   </TooltipComponent>
);

const Navbar = () => {
   const {
      currentColor,
      activeMenu,
      setActiveMenu,
      handleClick,
      isClicked,
      setScreenSize,
      screenSize,
   } = useStateContext();
   const { userInfo } = useAuth();
   const queryClient = useQueryClient();
   const { isLoading, data } = useCustomer(userInfo.storeId);
   const { addCustomer } = useAddCustomer();
   const [showAddCustomerDialog, setShowAddCustomerDialog] =
      React.useState(false);

   const [autoObj, setAutoObj] = React.useState(null);
   let navigate = useNavigate();
   let customerData = data?.map((customer) => {
      return {
         Id: customer.id,
         Text:
            customer.first_name +
            ' ' +
            customer.last_name +
            '-' +
            customer.phone_number,
      };
   });
   useEffect(() => {
      const handleResize = () => setScreenSize(window.innerWidth);

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
   }, []);

   useEffect(() => {
      if (screenSize <= 900) {
         setActiveMenu(false);
      } else {
         setActiveMenu(true);
      }
   }, [screenSize]);

   const handleActiveMenu = () => setActiveMenu(!activeMenu);
   function handleSelect(args) {
      let customerId =
         customerData[
            customerData.findIndex((x) => x.Text === args.itemData.Text)
         ].Id;
      navigate(`/customer-detail/${customerId}`);
      autoObj.clear();
   }

   function handleClose(value) {
      if (value.phone !== '') {
         //add customer
         addCustomer(value, {
            onSuccess: () => {
               toast.success('Customer added successfully');
               //refresh customer list
               queryClient.invalidateQueries('customers');
            },
            onError: (error) => {
               toast.error(error.response.data.message);
            },
         });
      }else{
         toast.error('Please fill all the fields');
      }
      setShowAddCustomerDialog(false);
   }
   function handleCancel() {
      setShowAddCustomerDialog(false);
   }
  
   return (
      <div className='flex justify-between p-2 md:ml-6 md:mr-12 relative'>
         <NavButton
            title='Menu'
            customFunc={handleActiveMenu}
            color={currentColor}
            icon={<AiOutlineMenu />}
         />
         <div className='container flex mx-auto'>
            <div className='flex border-2 rounded'>
               <button className='flex items-center justify-center px-4 border-r'>
                  <svg
                     className='w-6 h-6 text-gray-600'
                     fill='currentColor'
                     xmlns='http://www.w3.org/2000/svg'
                     viewBox='0 0 24 24'
                  >
                     <path d='M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'></path>
                  </svg>
               </button>

               <AutoCompleteComponent
                  id='CustomerID'
                  dataSource={customerData}
                  placeholder='客户搜索'
                  fields={{ value: 'Text' }}
                  className='px-4 py-2 w-60'
                  select={handleSelect}
                  ref={(autoObj) => setAutoObj(autoObj)}
               ></AutoCompleteComponent>
            </div>
            <div className='ml-10'>
               <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  onClick={() => setShowAddCustomerDialog(true)}
               >
                  添加客户
               </button>
            </div>
         </div>

         <div className='flex'>
            {/* <NavButton
               title='Cart'
               customFunc={() => handleClick('cart')}
               color={currentColor}
               icon={<FiShoppingCart />}
            />
            <NavButton
               title='Chat'
               dotColor='#03C9D7'
               customFunc={() => handleClick('chat')}
               color={currentColor}
               icon={<BsChatLeft />}
            />
            <NavButton
               title='Notification'
               dotColor='rgb(254, 201, 15)'
               customFunc={() => handleClick('notification')}
               color={currentColor}
               icon={<RiNotification3Line />}
            /> */}
            <TooltipComponent content='Profile' position='BottomCenter'>
               <div
                  className='flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg'
                  onClick={() => handleClick('userProfile')}
               >
                  <img
                     className='rounded-full w-8 h-8'
                     src={avatar}
                     alt='user-profile'
                  />
                  <p>
                     <span className='text-gray-400 text-14'>Hi,</span>{' '}
                     <span className='text-gray-400 font-bold ml-1 text-14'>
                        {userInfo.name}
                     </span>
                  </p>
                  <MdKeyboardArrowDown className='text-gray-400 text-14' />
               </div>
            </TooltipComponent>

            {isClicked.cart && <Cart />}
            {isClicked.chat && <Chat />}
            {isClicked.notification && <Notification />}
            {isClicked.userProfile && <UserProfile />}
           
         </div>

         <AddCustomerDialog
            open={showAddCustomerDialog}
            onClose={handleClose}
            onCancel={handleCancel}
         />
         <ToasterNotification />
      </div>
   );
};

export default Navbar;

import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const deleteEmployeeService = async ({employee_id}) => {
    console.log(employee_id,'😀')
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/service/delete/employee?employee_id=${employee_id}`,
    );
    
    return data;
    };

export function useDeleteEmployeeService() {
    const { isLoading, mutateAsync } = useMutation(deleteEmployeeService);
    return { isUpdatingEmployee: isLoading, deleteService: mutateAsync };
}

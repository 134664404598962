import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './components';
import {
   Login,
   Orders,
   Employees,
   Customers,
   Customers2,
   Appointment,
   CustomerDetail,
   Service,
   Members,
   Bill,
   Conversation,
   Feedback,
   EmployeeSchedule,
   Payment,
   Report,
} from './pages';

import Test from './pages/Test';

const AppRoutes = () => {
   return (
      <Routes>
         <Route element={<PrivateRoute isAllowed={true} />}>
            <Route path='/test' element={<Test />} />

            {/* pages  */}
            <Route path='/appointment' element={<Appointment />} />
            <Route path='/service' element={<Service />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/employees' element={<Employees />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/customers2' element={<Customers2 />} />
            <Route path='/customer-detail/:id' element={<CustomerDetail />} />
            <Route path='/conversation' element={<Conversation />} />
            <Route path='/feedback' element={<Feedback />} />
            <Route path='/employee_schedule' element={<EmployeeSchedule />} />
            <Route path='/bill' element={<Bill />} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/report' element={<Report />} />
            <Route path='/members' element={<Members />} />
         </Route>
         <Route path='/' element={<Login />} />
         <Route path='/login' element={<Login />} />
         <Route path='/403' element={403} />
      </Routes>
   );
};

export default AppRoutes;

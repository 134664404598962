import { useQuery } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';
import AuthHeader from '../authHeader';

export function useCategories() {
   return useQuery(['categories'], async () => {
      const { category } = await request(
         process.env.REACT_APP_GRAPHQL_SERVER,
         gql`
            query GetCategories {
               category {
                  id
                  name
               }
            }
         `,
         {},
         AuthHeader()
      );
      return category;
   });
}

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchCustomer = async (phone,store_id) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/customer/phone?phone=${phone}&store_id=${store_id}`,
   );

   return data;
};

export function useCustomerByPhone(phone,store_id) {
   return useQuery(["customer",phone,store_id], () => fetchCustomer(phone,store_id));
}

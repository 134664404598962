import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const addEmployeeHour = async (employee_hour) => {
    console.log(employee_hour,'😀')
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/employee/add_hour`,
        employee_hour
    );
    
    return data;
    };

export function useAddEmployeeHour() {
    const { isLoading, mutateAsync } = useMutation(addEmployeeHour);
    return { isAddingEmployeeHour: isLoading, addEmployeeHour: mutateAsync };
}

import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
   CheckBoxComponent,
   RadioButtonComponent,
   ButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { useAuth } from '../contexts/AuthProvider';
import moment from 'moment-timezone';
import { useUpdateMember } from '../services/hooks/useUpdateMember';

const EditMemberDialog = ({ open, onClose, member }) => {
   const { userInfo } = useAuth();

   const [customer_id, setCustomerId] = React.useState(null);
   const [first_name, setFirstName] = React.useState(member[0].first_name);
   const [last_name, setLastName] = React.useState(member[0].last_name);
   const [phone_number, setPhoneNumber] = React.useState(member[0].phone_number);
   const [expiration_date, setExpirationDate] = React.useState(
      moment(member[0].expiration_date).format('YYYY-MM-DD')
   );
   const [member_type_id, setMemberTypeId] = React.useState(member[0].member_type_id);
   const [error, setError] = React.useState('');
   const [message, setMessage] = React.useState('');
  const {isUpdating,updateMember} = useUpdateMember();

   const [btnEnabled, setBtnEnabled] = React.useState(false);
   let memberTypeData = [
      { id: 1, name: 'Gold' },
      { id: 2, name: 'Diamond' },
   ];
   let fields = { text: 'name', value: 'id' };

   function handleSaveMember() {
      updateMember(
         {
            member_id: member[0].member_id,
            customer_id: member[0].id,
            member_type_id,
            expiration_date,
            store_id: userInfo.storeId,
         },
         {
            onSuccess: () => {
               setMessage('更新成功');
               setBtnEnabled(false);
            },
            onError: (error) => {
               setError(error);
            },
         }
      );
   }
   return (
      <DialogComponent
         id='dialog'
         header='修改会员'
         target='#target'
         width={'437px'}
         showCloseIcon={true}
         visible={open}
         close={() => onClose()}
      >
         <div>
            <div className='flex flex-row mt-4'>
               <div className='w-1/2'>
                  First Name：
                  <input
                     type='text'
                     className='px-4 py-2 w-60'
                     placeholder='First Name'
                     value={first_name}
                     onChange={(e) => setFirstName(e.target.value)}
                  />
               </div>
               <div className='w-1/2'>
                  Last Name：
                  <input
                     type='text'
                     className='px-4 py-2 w-60'
                     placeholder='Last Name'
                     value={last_name}
                     onChange={(e) => setLastName(e.target.value)}
                  />
               </div>
            </div>
         </div>
         <div className='flex flex-row mt-4'>
            <div className='w-1/2'>
               Phone：{' '}
               <input
                  type='text'
                  className='px-4 py-2 w-60'
                  placeholder='Phone Number'
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
               />
            </div>
            <div className='w-1/2'>
               Expiration：{' '}
               <input
                  type='text'
                  className='px-4 py-2 w-60'
                  placeholder='Expiration Date'
                  value={expiration_date}
                  onChange={(e) => setExpirationDate(e.target.value)}
               />
            </div>
         </div>
         <div className='flex flex-row mt-4 items-baseline'>
            <div>Member Type:</div>
            <div className='w-32 ml-5'>
               <DropDownListComponent
                  id='tip_payment'
                  dataSource={memberTypeData}
                  fields={fields}
                  placeholder='Select a type'
                  value={member_type_id}
                  onChange={(e) => {
                     setMemberTypeId(e.value);
                  }}
                  popupHeight='100px'
               />
            </div>
         </div>
         <div className='mt-4'>
            {/* show error */}
            {error && <div className='text-red-500'>{error}</div>}
            {/* show message */}
            {message && <div className='text-green-500'>{message}</div>}
         </div>
         <div className='mt-10'>
            <button
               className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
               onClick={() => handleSaveMember()}
               disabled={isUpdating}
            >
               保存
            </button>
         </div>
      </DialogComponent>
   );
};

export default EditMemberDialog;

import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const addService = async (service) => {
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/service/add`,
        service
    );
    
    return data;
    };

export function useAddService() {
    const { isLoading, mutateAsync } = useMutation(addService);
    return { isAddingService: isLoading, addService: mutateAsync };
}

import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const addEmployeeService = async (services) => {
    console.log(services)
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/employee/add_services`,
        services
    );
    
    return data;
    };

export function useAddEmployeeService() {
    const { isLoading, mutateAsync } = useMutation(addEmployeeService);
    return { isAddingEmployeeService: isLoading, addService: mutateAsync };
}

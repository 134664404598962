import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const updateMember = async (member) => {
    console.log('updateMember', member);
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/member/update`,
        member
    );
    
    return data;
    };

export function useUpdateMember() {
    const { isLoading, mutateAsync } = useMutation(updateMember);
    return { isUpdatingMember: isLoading, updateMember: mutateAsync };
}

export default function AuthHeader() {
   const user = localStorage.getItem('user');
   if (user) {
      let obj = JSON.parse(user);
      return { authorization: 'Bearer ' + obj.token }; // for Spring Boot back-end
      //   return { 'x-access-token': user.token };       // for Node.js Express back-end
   } else {
      return {};
   }
}

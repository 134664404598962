import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';

import { registerLicense } from '@syncfusion/ej2-base';
import { ContextProvider } from './contexts/ContextProvider';
import AuthProvider from './contexts/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@material-tailwind/react';

const queryClient = new QueryClient();

// Registering Syncfusion license key
registerLicense(
   'ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1hWX5XcnBRR2JeWEw='
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
      <BrowserRouter>
         <AuthProvider>
            <ContextProvider>
               <QueryClientProvider client={queryClient}>
                  <ThemeProvider>
                     <App />
                  </ThemeProvider>
                  <ReactQueryDevtools initialIsOpen={true} />
               </QueryClientProvider>
            </ContextProvider>
         </AuthProvider>
      </BrowserRouter>
   </React.StrictMode>
);

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchCustomersReport = async (storeId) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/customer/count?store_id=${storeId}`
   );

   return data;
};

export function useCustomersReport(storeId) {
   return useQuery(["customers_report",storeId], () => fetchCustomersReport(storeId));
}

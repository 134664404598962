import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import { ToastContainer, toast } from 'react-toastify';
import { loginUser } from '../services/dataService';
const Login = () => {
   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm();

   const { login } = useAuth();
   // const { isLoading, data: user } = useLogin(
   //    watch('email'),
   //    watch('password')
   // );
   let navigate = useNavigate();

   useEffect(() => {
      //check if user logged in
      if (login.userInfo) {
         navigate('/appointment');
      }
   }, [login.userInfo]);

   const onSubmit = (data) => {
      // if (user && user.token) {
      //    login(user);
      //    navigate('/ecommerce');
      // }else{
      //    toast.error("Wrong email or password !", {
      //       position: toast.POSITION.TOP_RIGHT
      //     });
      // }
      loginUser(data).then((user) => {
         if (user && user.token) {
            login(user);
            navigate('/appointment');
         } else {
            toast.error('Wrong email or password !', {
               position: toast.POSITION.TOP_RIGHT,
            });
         }
      });
   };

   return (
      <section className='flex flex-col md:flex-row h-screen items-center'>
         <div className='bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen'>
            <img
               src='https://source.unsplash.com/featured/1200x1200'
               alt=''
               className='w-full h-full object-cover'
            />
         </div>

         <div
            className='bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
        flex items-center justify-center'
         >
            <div className='w-full h-100'>
               <h1 className='text-xl md:text-2xl font-bold leading-tight mt-12'>
                  Log in to your account
               </h1>

               {/* <form className='mt-6' action='/Ecommerce' method='POST'> */}
               <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
                  <div>
                     <label className='block text-gray-700'>
                        Email Address
                     </label>
                     <input
                        type='email'
                        name=''
                        id=''
                        placeholder='Enter Email Address'
                        className='w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none'
                        autoFocus
                        required
                        // value={email}
                        // onChange={(e) => setEmail(e.target.value)}
                        {...register('email')}
                     />
                     {errors.email && <span>This field is required</span>}
                  </div>

                  <div className='mt-4'>
                     <label className='block text-gray-700'>Password</label>
                     <input
                        type='password'
                        name='password'
                        id='password'
                        placeholder='Enter Password'
                        minLength='6'
                        className='w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none'
                        required
                        // value={password}
                        // onChange={(e) => setPassword(e.target.value)}
                        {...register('password')}
                     />
                     {errors.password && <span>This field is required</span>}
                  </div>

                  <button
                     type='submit'
                     className='w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
              px-4 py-3 mt-6'
                  >
                     Log In
                  </button>
               </form>
            </div>
            <ToastContainer />
         </div>
      </section>
   );
};

export default Login;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchSalesReport = async (storeId) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/order/salesreport?store_id=${storeId}`
   );

   return data;
};

export function useSalesReport(storeId) {
   return useQuery(["sales_report",storeId], () => fetchSalesReport(storeId));
}

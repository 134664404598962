import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Selection,
   Inject,
   Edit,
   Toolbar,
} from '@syncfusion/ej2-react-grids';
import React from 'react';
import { Header } from '../components';

import moment from 'moment-timezone';
import { useAuth } from '../contexts/AuthProvider';
import { checkInOrder, unCheckInOrder } from '../services/dataService';
import { FaCheck } from 'react-icons/fa';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
   NumericTextBoxComponent,
   TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import { getService, checkOutOrderService } from '../services/dataService';
import { formatMoney } from '../services/utils';
import { Typography } from '@material-tailwind/react';
import { useDailyOrders } from '../services/hooks/useDailyOrders';
import { useDailyOrderCashTotal } from '../services/hooks/useDailyOrderCashTotal';
import { useDailyOrderCreditCardTotal } from '../services/hooks/useDailyOrderCreditCardTotal';
import { useQueryClient } from '@tanstack/react-query';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useDailyOrderGrouponTotal } from '../services/hooks/useDailyOrderGrouponTotal';
import { useDailyOrderGiftCardTotal } from '../services/hooks/useDailyOrderGiftCardTotal';
const Bill = () => {
   let { userInfo } = useAuth();
   // Get QueryClient from the context
   const queryClient = useQueryClient();
   const [date, setDate] = React.useState(new Date());
   const { isLoading, data } = useDailyOrders(
      userInfo.storeId,
      moment(date).format('YYYY-MM-DD')
   );
   const { data: cashTotal } = useDailyOrderCashTotal(
      userInfo.storeId,
      moment.utc(date).tz('America/New_York').format('YYYY-MM-DD')
   );
   const { data: cardTotal } = useDailyOrderCreditCardTotal(
      userInfo.storeId,
      moment(date).format('YYYY-MM-DD')
   );
   const { data: grouponTotal } = useDailyOrderGrouponTotal(
      userInfo.storeId,
      moment(date).format('YYYY-MM-DD')
   );
   const { data: giftcardTotal } = useDailyOrderGiftCardTotal(
      userInfo.storeId,
      moment(date).format('YYYY-MM-DD')
   );

   const [showDialog, setShowDialog] = React.useState(false);
   const [selectedOrder, setSelectedOrder] = React.useState(null);
   const [servicePayment, setServicePayment] = React.useState(0);
   const [servicePaymentType, setServicePaymentType] = React.useState(1);
   const [tipPayment, setTipPayment] = React.useState(0);
   const [tipPaymentType, setTipPaymentType] = React.useState(1);
   const [otherPayment, setOtherPayment] = React.useState(0);
   const [otherPaymentType, setOtherPaymentType] = React.useState(1);
   const [totalPayment, setTotalPayment] = React.useState(0);
   const [notes, setNotes] = React.useState('');
   const [error, setError] = React.useState('');

   let paymentData = [
      { id: 1, name: '信用卡' },
      { id: 2, name: '现金' },
      { id: 3, name: 'Groupon' },
      { id: 4, name: '礼卡' },
   ];
   let fields = { text: 'name', value: 'id' };

   const editSettings = {
      allowEditing: false,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: 'Top',
   };

   function customerTemplate(arg) {
      return (
         <div>
            <div>
               {arg.first_name}&nbsp;{arg.last_name}
            </div>
         </div>
      );
   }
   function checkIn(arg) {
      let data = { order_id: arg.OrderID };
      checkInOrder(data)
         .then((res) => {
            queryClient.invalidateQueries({ queryKey: ['daily_orders'] });
         })
         .catch((err) => {
            console.log(err);
         });
   }
   function unCheckin(arg) {
      let data = { order_id: arg.OrderID };
      unCheckInOrder(data)
         .then((res) => {
            queryClient.invalidateQueries({ queryKey: ['daily_orders'] });
         })
         .catch((err) => {
            console.log(err);
         });
   }

   function checkInTemplate(arg) {
      return (
         <div className='flex justify-end'>
            {!arg.is_checked_in && (
               <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  onClick={() => checkIn(arg)}
               >
                  Check In
               </button>
            )}
            {arg.is_checked_in && (
               <div
                  className='text-green-500 font-bold py-2 px-4 rounded text-end '
                  onClick={() => unCheckin(arg)}
               >
                  <FaCheck />
               </div>
            )}
         </div>
      );
   }
   async function checkOut(arg) {
      if (arg.is_checked_out) {
         setServicePayment(arg.service_payment);
         setServicePaymentType(arg.service_payment_type);
         setTipPayment(arg.tip_payment);
         setTipPaymentType(arg.tip_payment_type);
         setOtherPayment(arg.other_payment);
         setOtherPaymentType(arg.other_payment_type);
         setTotalPayment(arg.total_payment);
         console.log(arg,"😀")
         setNotes(arg.notes);
      } else {
         let result = await getService(arg.ServiceID);
         if (result) {
            setServicePayment(Number(result.price));
            setTotalPayment(Number(result.price) + Number(tipPayment) + Number(otherPayment));
            console.log(result.price)
            console.log(tipPayment)
            console.log(otherPayment)
         }
      }
      setSelectedOrder(arg);
      setShowDialog(true);
   }

   function checkOutTemplate(arg) {
      return (
         <div className='flex justify-end'>
            {!arg.is_checked_out && (
               <button
                  className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
                  onClick={() => checkOut(arg)}
               >
                  Check Out
               </button>
            )}
            {arg.is_checked_out && (
               <div
                  className='text-green-500 font-bold py-2 px-4 rounded text-end '
                  onClick={() => checkOut(arg)}
               >
                  <FaCheck />
               </div>
            )}
         </div>
      );
   }
   function timeTemplate(arg) {
      return (
         <div>
            <div>{moment(arg.StartTime).format('MM/DD HH:mm')}</div>
         </div>
      );
   }
   function handleCloseDialog() {
      setShowDialog(false);
   }
   function handleServicePaymentChange(args) {
      console.log(parseFloat(args.value) + parseFloat(tipPayment) + parseFloat(otherPayment))
      setServicePayment(parseFloat(args.value));
      setTotalPayment(parseFloat(args.value) + parseFloat(tipPayment) + parseFloat(otherPayment));
   }
   function handleTipPaymentChange(args) {
      setTipPayment(args.value);
      setTotalPayment(parseFloat(args.value) + parseFloat(servicePayment) + parseFloat(otherPayment));
   }
   function handleOtherPaymentChange(args) {
      setOtherPayment(args.value);
      setTotalPayment(parseFloat(args.value) + parseFloat(servicePayment) + parseFloat(tipPayment));
   }
   function checkOutOrder() {
      setError('');
      checkOutOrderService({
         orderId: selectedOrder.OrderID,
         servicePayment: servicePayment,
         servicePaymentType: servicePaymentType,
         tipPayment: tipPayment,
         tipPaymentType: tipPaymentType,
         otherPayment: otherPayment,
         otherPaymentType: otherPaymentType,
         totalPayment: totalPayment,
         notes: notes,
      })
         .then((res) => {
            setShowDialog(false);
            // if (gridInstance) {
            //    gridInstance.refresh();
            // }

            //refresh grid
            queryClient.invalidateQueries({ queryKey: ['daily_orders'] });
            queryClient.invalidateQueries({
               queryKey: ['order_credit_card_total'],
            });
            queryClient.invalidateQueries({ queryKey: ['order_cash_total'] });
            queryClient.invalidateQueries({
               queryKey: ['order_groupon_total'],
            });
            queryClient.invalidateQueries({
               queryKey: ['order_giftcard_total'],
            });
            //reset default value
            setServicePayment(0);
            setServicePaymentType(1);
            setTipPayment(0);
            setTipPaymentType(1);
            setOtherPayment(0);
            setOtherPaymentType(1);
            setTotalPayment(0);
            setNotes('');
         })
         .catch((err) => {
            console.log(err);
            setError(err.message);
         });
   }

   if (isLoading) {
      return <div>Loading...</div>;
   }
   function handleDateChange(e) {
      setDate(e.target.value);
   }

   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header category='Page' title='账单管理' />
         <div id='target' className='col-lg-12 target-element'></div>
         <div className='w-48 mb-6 flex flex-row items-center justify-center'>
            <span className='w-16'>日期：</span>
            <DatePickerComponent
               value={date}
               onChange={(e) => {
                  handleDateChange(e);
               }}
            ></DatePickerComponent>
         </div>
         <div className='w-3/4'>
            <GridComponent
               id='bill'
               dataSource={data}
               editSettings={editSettings}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field='StartTime'
                     headerText='时间'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                     template={timeTemplate}
                  />
                  <ColumnDirective
                     field='name'
                     headerText='服务'
                     width='130'
                     textAlign='Right'
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='phone_number'
                     headerText='电话号码'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='first_name'
                     headerText='客户'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                     template={customerTemplate}
                  />

                  <ColumnDirective
                     field='status'
                     headerText='Check In'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                     template={checkInTemplate}
                  />
                  <ColumnDirective
                     field='status'
                     headerText='Check Out'
                     width='120'
                     textAlign='Right'
                     allowEditing={false}
                     template={checkOutTemplate}
                  />
               </ColumnsDirective>
               <Inject services={[Selection]} />
            </GridComponent>
            <div className='flex flex-row'>
               <Typography variant='h6' className='mt-5 mr-10'>
                  信用卡： {formatMoney(cardTotal?.total)}
               </Typography>
               <Typography variant='h6' className='mt-5 mr-10'>
                  现金：{formatMoney(cashTotal?.total)}
               </Typography>
               <Typography variant='h6' className='mt-5 mr-10'>
                  礼品卡：{formatMoney(giftcardTotal?.total)}
               </Typography>
               <Typography variant='h6' className='mt-5 mr-10'>
                  Groupon：{formatMoney(grouponTotal?.total)}
               </Typography>
               <Typography variant='h6' className='mt-5 mr-10'>
                  总额：
                  {formatMoney(
                     cardTotal?.total +
                        cashTotal?.total +
                        giftcardTotal?.total +
                        grouponTotal?.total
                  )}
               </Typography>
            </div>
         </div>
         <DialogComponent
            id='dialog'
            header='Check out'
            target='#target'
            width={'437px'}
            showCloseIcon={true}
            visible={showDialog}
            close={handleCloseDialog}
         >
            <div>
               <div className='mt-4'>{selectedOrder?.name}</div>
               <div className='flex flex-row mt-4'>
                  <div className='w-1/2'>
                     服务费：
                     <div className='w-32'>
                        <NumericTextBoxComponent
                           format='c2'
                           value={servicePayment}
                           min={0}
                           onChange={handleServicePaymentChange}
                        ></NumericTextBoxComponent>
                     </div>
                  </div>
                  <div className='w-1/2'>
                     付款方式：
                     <DropDownListComponent
                        id='service_payment'
                        dataSource={paymentData}
                        fields={fields}
                        placeholder='Select a game'
                        value={servicePaymentType}
                        onChange={(e) => {
                           setServicePaymentType(e.value);
                        }}
                        popupHeight='220px'
                     />
                  </div>
               </div>
               <div className='flex flex-row mt-4'>
                  <div className='w-1/2'>
                     小费：
                     <div className='w-32'>
                        <NumericTextBoxComponent
                           format='c2'
                           value={tipPayment}
                           onChange={handleTipPaymentChange}
                        ></NumericTextBoxComponent>
                     </div>
                  </div>
                  <div className='w-1/2'>
                     付款方式：
                     <DropDownListComponent
                        id='tip_payment'
                        dataSource={paymentData}
                        fields={fields}
                        placeholder='Select a payment'
                        value={tipPaymentType}
                        onChange={(e) => {
                           setTipPaymentType(e.value);
                        }}
                        popupHeight='220px'
                     />
                  </div>
               </div>
               <div className='flex flex-row mt-4'>
                  <div className='w-1/2'>
                     其它：
                     <div className='w-32'>
                        <NumericTextBoxComponent
                           format='c2'
                           value={otherPayment}
                           onChange={handleOtherPaymentChange}
                        ></NumericTextBoxComponent>
                     </div>
                  </div>
                  <div className='w-1/2'>
                     付款方式：
                     <DropDownListComponent
                        id='other_payment'
                        dataSource={paymentData}
                        fields={fields}
                        placeholder='Select a payment'
                        value={otherPaymentType}
                        onChange={(e) => {
                           setOtherPaymentType(e.value);
                        }}
                        popupHeight='220px'
                     />
                  </div>
               </div>
               <div className='mt-4'>总计：{formatMoney(totalPayment)}</div>

               <div className='mt-4'>
                  <TextBoxComponent
                     id='notes'
                     multiline={true}
                     floatLabelType='Auto'
                     placeholder='Notes'
                     value={notes}
                     onChange={(e) => setNotes(e.target.value)}
                  ></TextBoxComponent>
               </div>
               <div className='mt-4'>
                  {/* show error */}
                  {error && <div className='text-red-500'>{error}</div>}
               </div>
               <div className='mt-10'>
                  <button
                     className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
                     onClick={() => checkOutOrder()}
                  >
                     Check Out
                  </button>
               </div>
            </div>
         </DialogComponent>
      </div>
   );
};

export default Bill;

import moment from 'moment';
import React from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { useCustomerMessages } from '../../services/hooks/useCustomerMessages2';
import CustomerMessage from './CustomerMessage';
import SystemMessage from './SystemMessage';
import { sendMessage } from '../../services/dataService';
import { useQueryClient } from '@tanstack/react-query';

const ChatContent = ({ first_name, last_name, phone }) => {
   const { userInfo } = useAuth();
   const queryCLient = useQueryClient();
   const [message, setMessage] = React.useState();
   const { isLoading, data, error } = useCustomerMessages(
      phone,
      userInfo.storeId
   );
   if (isLoading) {
      return <div>Loading...</div>;
   }
   if (error) {
      console.log(error);
      return <div>Error</div>;
   }
   let initial = '';
   if (last_name !== '') {
      initial =
         first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase();
   } else {
      initial = first_name.charAt(0).toUpperCase();
   }
   function handleSend() {

      setMessage('');

      data.push({
         content: message,
         from_customer: false,
         updated_at: moment.utc(),
      });
      sendMessage({
         store_id: userInfo.storeId,
         phone: phone,
         msg: message,
      }).then((res) => {
         queryCLient.invalidateQueries('customer_messages');
      });
   }
   return (
      <div className='chat-area flex-1 flex flex-col ml-5'>
         <div className='flex-3'>
            <h2 className='text-xl py-1 mb-8 border-b-2 border-gray-200'>
               Chatting with{' '}
               <b>
                  {first_name} {last_name} ({phone})
               </b>
            </h2>
         </div>
         <div className='messages flex-1 overflow-auto'>
            {data.map((message) =>
               message.from_customer ? (
                  <CustomerMessage
                     message={message.content}
                     initial={initial}
                     time={message.created_at}
                     key={message.id}
                  />
               ) : (
                  <SystemMessage
                     message={message.content}
                     time={message.created_at}
                     key={message.id}
                  />
               )
            )}
         </div>
         <div className='flex-2 pt-4 pb-10'>
            <div className='write bg-white shadow flex rounded-lg'>
               <div className='flex-3 flex content-center items-center text-center p-4 pr-0'>
                  <span className='block text-center text-gray-400 hover:text-gray-800'>
                     <svg
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        className='h-6 w-6'
                     >
                        <path d='M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                     </svg>
                  </span>
               </div>
               <div className='flex-1'>
                  <textarea
                     name='message'
                     className='w-full block outline-none py-4 px-4 bg-transparent'
                     rows='1'
                     placeholder='Type a message...'
                     autoFocus
                     value={message}
                     onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
               </div>
               <div className='flex-2 w-32 p-2 flex content-center items-center'>
                  <div className='flex-1 text-center'>
                     {/* <span className='text-gray-400 hover:text-gray-800'>
                        <span className='inline-block align-text-bottom'>
                           <svg
                              fill='none'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              stroke='currentColor'
                              viewBox='0 0 24 24'
                              className='w-6 h-6'
                           >
                              <path d='M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13'></path>
                           </svg>
                        </span>
                     </span> */}
                  </div>
                  <div className='flex-1'>
                     <button
                        className='bg-blue-400 w-10 h-10 rounded-full inline-block'
                        onClick={handleSend}
                     >
                        <span className='inline-block align-text-bottom'>
                           <svg
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              viewBox='0 0 24 24'
                              className='w-4 h-4 text-white'
                           >
                              <path d='M5 13l4 4L19 7'></path>
                           </svg>
                        </span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ChatContent;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchAppointmentDetail = async (eventId) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/scheduleevents/detail?id=${eventId}`
   );

   return data;
};

export function useAppointment(eventId) {
   return useQuery(["appointment_detail",eventId], () => fetchAppointmentDetail(eventId));
}

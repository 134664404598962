import {
   TabComponent,
   TabItemDirective,
   TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCustomer } from '../services/hooks/useCustomer';
import {
   Activities,
   Appointments,
   Checkins,
   Forms,
   Invoices,
   Photos,
} from '../components/Customers';
import { useAuth } from '../contexts/AuthProvider';
import { useStateContext } from '../contexts/ContextProvider';

const CustomerDetail = () => {
   const { userInfo } = useAuth();
   const {setSelectedCustomer} = useStateContext();
   const { isLoading, data: customer } = useCustomer(
      useParams().id,
      userInfo.storeId
   );
   
   setSelectedCustomer(customer?customer[0]:null);

   let headerText = [
      { text: 'Appointments' },
      // { text: 'Activities' },
      // { text: 'Forms' },
      // { text: 'Appointments' },
      // { text: 'Photos' },
      // { text: 'Invoices' },
   ];
   if (isLoading) {
      return <div>Loading...</div>;
   }
   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen'>
         <div>
            {customer[0]?.first_name} {customer[0]?.last_name}
         </div>
         <TabComponent heightAdjustMode='Auto' id='defaultTab'>
            <TabItemsDirective>
               <TabItemDirective header={headerText[0]} content={Checkins} />

               {/* <TabItemDirective header={headerText[1]} content={Activities} />

               <TabItemDirective header={headerText[2]} content={Forms} />

               <TabItemDirective
                  header={headerText[3]}
                  content={Appointments}
               />

               <TabItemDirective header={headerText[4]} content={Photos} />

               <TabItemDirective header={headerText[5]} content={Invoices} /> */}
            </TabItemsDirective>
         </TabComponent>
      </div>
   );
};

export default CustomerDetail;

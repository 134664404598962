import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const addCustomer = async (customer) => {
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/customer/add`,
        customer
    );
    
    return data;
    };

export function useAddCustomer() {
    const { isLoading, mutateAsync } = useMutation(addCustomer);
    return { isAddingCustomer: isLoading, addCustomer: mutateAsync };
}

import React from 'react';
import { useEmployeeService } from '../services/hooks/useEmployeeService2';

const EmployeeServices = ({ employee_id }) => {
   const { data, error, isLoading } = useEmployeeService(employee_id);
   if (isLoading) return <div>Loading...</div>;
   if (error) return <div>Error: {error.message}</div>;

   return (
      <div className='flex flex-row items-stretch'>
         <div>
            {data?.map((service, index) => (
               <div className='my-5' key={index}>
                  <span className=' border-2 rounded-lg p-2 m-2'>
                     {service.name}
                  </span>
               </div>
            ))}
         </div>
      </div>
   );
};

export default EmployeeServices;

import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const addMember = async (member) => {
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/member/add`,
        member
    );
    
    return data;
    };

export function useAddMember() {
    const { isLoading, mutateAsync } = useMutation(addMember);
    return { isAddingMember: isLoading, addMember: mutateAsync };
}

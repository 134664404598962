import React from 'react';
import { Header } from '../components';
import { useAuth } from '../contexts/AuthProvider';
import { useMembers } from '../services/hooks/useMembers';
import moment from 'moment-timezone';

import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Page,
   Selection,
   Inject,
   Edit,
   Toolbar,
   Sort,
   Filter,
   Search,
} from '@syncfusion/ej2-react-grids';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import AddMemberDialog from '../components/AddMemberDialog';
import EditMemberDialog from '../components/EditMemberDialog';
import { getMemberInfo } from '../services/dataService';

const Members = () => {
   const { userInfo } = useAuth();
   // const { isLoading, data, error } = useMembers(userInfo.storeId);
   const [openAdd, setOpenAdd] = React.useState(false);
   const [openEdit, setOpenEdit] = React.useState(false);
   const [selectedId, setSelectedId] = React.useState(null);
   const [selectedMember, setSelectedMember] = React.useState(null);

   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      url:
         process.env.REACT_APP_API_SERVER +
         `/api/member/syncfusion/${userInfo.storeId}`,
   });
   const editSettings = {
      allowEditing: false,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: 'Top',
   };
   const toolbarOptions = ['Search'];

   // if (isLoading) {
   //    return <div>Loading...</div>;
   // }
   // if (error) {
   //    console.log(error);
   //    return <div>Error</div>;
   // }
   function expirationTemplate(props) {
      return (
         <div className='flex flex-row'>
            <div>{moment(props.expiration_date).format('YYYY-MM-DD')}</div>
            {moment(props.expiration_date) < moment() && (
               <div className='text-red-500 ml-5'>已过期</div>
            )}
            {moment(props.expiration_date) >= moment() && (
               <div className='text-green-500 ml-5'>有效</div>
            )}
         </div>
      );
   }
   function memberTypeTemplate(props) {
      return (
         <>
            {props.member_type_id === 1 && (
               <div className='text-green-500'>金卡</div>
            )}
            {props.member_type_id === 2 && (
               <div className='text-green-500'>钻石卡</div>
            )}
         </>
      );
   }
   function editTemplate(arg) {
      return (
         <div className='flex justify-end'>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
               onClick={() => changeMember(arg)}
            >
               修改
            </button>
         </div>
      );
   }
   function handleCloseAdd() {
      setOpenAdd(false);
   }
   function handleCloseEdit() {
      setOpenEdit(false);
   }

   function changeMember(arg) {
      console.log(arg);
      getMember(arg.member_id).then((res) => {
         setSelectedId(arg.member_id);
         setSelectedMember(res);
         setOpenEdit(true);
      });
   }

   async function getMember(id) {
      const member = await getMemberInfo(id);
      return member;
   }

   return (
      <>
         <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
            <Header category='Page' title='会员' />
            <div id='target' className='col-lg-12 target-element'></div>
            <div className='flex justify-start mb-5'>
               <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  onClick={() => setOpenAdd(true)}
               >
                  添加会员
               </button>
            </div>
            <div className='w-3/4'>
               <GridComponent
                  id='members'
                  dataSource={dataManager}
                  toolbar={toolbarOptions}
                  allowPaging={true}
                  editSettings={editSettings}
                  pageSettings={{ pageCount: 5, pageSize: 20 }}
               >
                  <ColumnsDirective>
                     <ColumnDirective
                        field='member_id'
                        headerText='ID'
                        width='60'
                        textAlign='Right'
                     />
                     <ColumnDirective
                        field='first_name'
                        headerText='First Name'
                        clipMode='EllipsisWithTooltip'
                        width='100'
                        allowEditing={false}
                     />
                     <ColumnDirective
                        field='last_name'
                        headerText='Last Name'
                        clipMode='EllipsisWithTooltip'
                        width='80'
                     />
                     <ColumnDirective
                        field='phone_number'
                        headerText='Phone'
                        width='140'
                     />
                     <ColumnDirective
                        field='expiration_date'
                        headerText='Expiration Date'
                        width='200'
                        template={expirationTemplate}
                     />
                     <ColumnDirective
                        field='member_type_id'
                        headerText='Type'
                        width='100'
                        template={memberTypeTemplate}
                     />
                     <ColumnDirective
                        field='member_id'
                        headerText='Edit'
                        width='120'
                        textAlign='Right'
                        allowEditing={false}
                        template={editTemplate}
                     />
                  </ColumnsDirective>
                  <Inject services={[Toolbar, Search]} />
               </GridComponent>
            </div>
         </div>
         {openAdd && (
            <AddMemberDialog open={openAdd} onClose={handleCloseAdd} />
         )}

         {openEdit && (
            <EditMemberDialog open={openEdit} onClose={handleCloseEdit} member={selectedMember} />
         )}
      </>
   );
};

export default Members;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchDailyCreditCardTotal = async (storeId,date) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/order/daily/creditcard?store_id=${storeId}&date=${date}`
   );
   return data;
};

export function useDailyOrderCreditCardTotal(storeId,date) {
   return useQuery(["order_credit_card_total",storeId,date], () => fetchDailyCreditCardTotal(storeId,date));
}

import React from 'react';
import { Header } from '../components';
import { AiFillPlusSquare } from 'react-icons/ai';
import { useAuth } from '../contexts/AuthProvider';
import { useEmployeeService } from '../services/hooks/useEmployeeService';
import EditEmployee from '../components/EditEmployee';
import EditEmployeeService from '../components/EditEmployeeService';
import EditEmployeeHour from '../components/EditEmployeeHour';
import AddEmployee from '../components/AddEmployee';
import { FaPeopleCarry } from 'react-icons/fa';
import { useAddEmployee } from '../services/hooks/useAddEmployee';
import { useQueryClient } from '@tanstack/react-query';
import { useEmployee } from '../services/hooks/useEmployee2';
import {useEmployees} from '../services/hooks/useEmployees';
import { useAddEmployeeService } from '../services/hooks/useAddEmployeeService';
import { useDeleteEmployeeService } from '../services/hooks/useDeleteEmployeeService';
import { addEmployee } from '../services/dataService';
import LoadingComponent from '../components/LoadingComponent';
import { useUpdateEmployee } from '../services/hooks/useUpdateEmployee';
import {EmployeeServices} from '../components';
// import { useServices } from '../services/hooks/useServices';

const Employees = () => {
   const queryClient = useQueryClient();
   const { userInfo } = useAuth();
   const [employee_id, setEmployee_id] = React.useState(1);
   const [open, setOpen] = React.useState(false);
   const [openService, setOpenService] = React.useState(false);
   const [openHour, setOpenHour] = React.useState(false);
   const [openAdd, setOpenAdd] = React.useState(false);
   
   const { isLoading: isLoadingEmployee, data: employee } =
      useEmployee(employee_id);
   const { isLoading: isLoadingEmployees, data: employees } = useEmployees(userInfo.storeId);
   const { addService } = useAddEmployeeService();
   const { deleteService } = useDeleteEmployeeService();
   const { updateEmployee} = useUpdateEmployee();

   if (isLoadingEmployee || isLoadingEmployees) return <LoadingComponent />;
  

   // const { mutate: addEmployee } = useAddEmployee();
   function handleClose() {
      setOpen(false);
   }
   // const {isLoading,error, data} = useServices(userInfo.storeId);
   // if (error) return <div>Error...</div>;
   function handleEdit(employee_id) {
      console.log(employee_id)
      setEmployee_id(employee_id);
      setOpen(true);
   }
   function handleEditService(employee_id) {
      setEmployee_id(employee_id);
      setOpenService(true);
   }
   function handleEditHour(employee_id) {
      setEmployee_id(employee_id);
      setOpenHour(true);
   }
   function handleDelete(employee_id) {
      console.log(employee_id);
   }
   function handleCloseService() {
      setOpenService(false);
   }
   function handleCloseHour() {
      setOpenHour(false);
   }
   function handleAdd() {
      setOpenAdd(true);
   }
   function handleCloseAdd() {
      setOpenAdd(false);
   }
   function handleSaveAdd(values) {
      let employee = {
         first_name: values.first_name,
         last_name: values.last_name,
         email: values.email,
         phone: values.phone,
         store_id: userInfo.storeId,
         is_active: true,
      }
      addEmployee(employee)
         .then((res) => {
            queryClient.invalidateQueries(['employee_service']);
            queryClient.invalidateQueries(['employee_hour']);
            setOpenAdd(false);
         })
         .catch((err) => {
            console.log(err);
         });
      // setOpenAdd(false);
      // console.log(values);
      // addEmployee([{ ...values, store_id: userInfo.storeId }], {
      //    onSuccess: (data, variables) => {
      //       console.log('success');
      //       setOpenAdd(false);
      //       return queryClient.invalidateQueries(['employee_service']);
      //    },
      //    onError: (error) => {
      //       console.log(error);
      //    },
      // });
   }
   function handleSaveEmployee(values) {

      updateEmployee({firstName: values.first_name, lastName: values.last_name, phone: values.phone, id: employee_id}
         , {
            onSuccess: (data, variables) => {
               setOpen(false);
               queryClient.invalidateQueries(['employee']);
               return queryClient.invalidateQueries(['employees']);
            },
            onError: (error) => {
               console.log(error);
            }
         })
   }
   function handleUpdateEmployeeService(values) {
      deleteService(
         { employee_id: employee_id },
         {
            onSuccess: () => {
               let keys = Object.keys(values).filter((key) => values[key]);
               let data = [];
               for (let i = 0; i < keys.length; i++) {
                  data.push({
                     service_id: parseInt(keys[i]),
                     employee_id: employee_id,
                  });
               }
               addService(data, {
                  onSuccess: () => {
                     return queryClient.invalidateQueries('employee_service').then(() => {
                                queryClient.invalidateQueries('employee');
                              });
                  },
                  onError: (error) => {
                     console.log(error);
                  },
               });
            },
            onError: (error) => {
               console.log(error);
            },
         }
      );

      setOpenService(false);
   }

   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header category='Page' title='Employees' />
         <div className='flex flex-col'>
            <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
               <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
                  <div className='overflow-hidden'>
                     <button
                        className='flex space-x-2 h-8 items-center px-3 py-2 bg-blue-500 hover:bg-blue-800 rounded-md drop-shadow-md'
                        onClick={() => handleAdd()}
                     >
                        <AiFillPlusSquare className='text-white' />
                        <span className='text-white'>Add Employee</span>
                     </button>
                     <table className='min-w-full'>
                        <thead className='border-b'>
                           <tr>
                              <th
                                 scope='col'
                                 className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                              >
                                 Name
                              </th>
                              <th
                                 scope='col'
                                 className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                              >
                                 Action
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           {employees?.map((employee, index) => (
                              <tr className='bg-white border-b' key={index}>
                                 <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                    <div className='flex flex-col'>
                                       <span>
                                          {employee.first_name}{' '}
                                          {employee.last_name}
                                       </span>
                                       <EmployeeServices employee_id={employee.id} />
                                    </div>
                                 </td>
                                 <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                                    <div className='flex justify-start pt-2 mt-5'>
                                       <button
                                          className='flex space-x-2 h-8 ml-5 items-center px-4 py-2 bg-green-600 hover:bg-amber-600 rounded-md drop-shadow-md'
                                          onClick={() =>
                                             handleEdit(employee.id)
                                          }
                                       >
                                          <svg
                                             className='fill-white'
                                             xmlns='http://www.w3.org/2000/svg'
                                             x='0px'
                                             y='0px'
                                             width='20'
                                             height='20'
                                             viewBox='0 0 50 50'
                                          >
                                             <path d='M 46.574219 3.425781 C 45.625 2.476563 44.378906 2 43.132813 2 C 41.886719 2 40.640625 2.476563 39.691406 3.425781 C 39.691406 3.425781 39.621094 3.492188 39.53125 3.585938 C 39.523438 3.59375 39.511719 3.597656 39.503906 3.605469 L 4.300781 38.804688 C 4.179688 38.929688 4.089844 39.082031 4.042969 39.253906 L 2.035156 46.742188 C 1.941406 47.085938 2.039063 47.453125 2.292969 47.707031 C 2.484375 47.898438 2.738281 48 3 48 C 3.085938 48 3.171875 47.988281 3.257813 47.964844 L 10.746094 45.957031 C 10.917969 45.910156 11.070313 45.820313 11.195313 45.695313 L 46.394531 10.5 C 46.40625 10.488281 46.410156 10.472656 46.417969 10.460938 C 46.507813 10.371094 46.570313 10.308594 46.570313 10.308594 C 48.476563 8.40625 48.476563 5.324219 46.574219 3.425781 Z M 45.160156 4.839844 C 46.277344 5.957031 46.277344 7.777344 45.160156 8.894531 C 44.828125 9.222656 44.546875 9.507813 44.304688 9.75 L 40.25 5.695313 C 40.710938 5.234375 41.105469 4.839844 41.105469 4.839844 C 41.644531 4.296875 42.367188 4 43.132813 4 C 43.898438 4 44.617188 4.300781 45.160156 4.839844 Z M 5.605469 41.152344 L 8.847656 44.394531 L 4.414063 45.585938 Z'></path>
                                          </svg>
                                          <span className='text-white text-md'>
                                             Edit
                                          </span>
                                       </button>
                                       <button
                                          className='flex space-x-2 h-8 ml-5 items-center px-4 py-2 bg-green-600 hover:bg-amber-600 rounded-md drop-shadow-md'
                                          onClick={() =>
                                             handleEditService(employee.id)
                                          }
                                       >
                                          <FaPeopleCarry className='text-white text-md' />
                                          <span className='text-white text-md'>
                                             Services
                                          </span>
                                       </button>
                                       <button
                                          className='flex space-x-2 h-8 ml-5 items-center px-4 py-2 bg-green-600 hover:bg-amber-600 rounded-md drop-shadow-md'
                                          onClick={() =>
                                             handleEditHour(employee.id)
                                          }
                                       >
                                          <span className='text-white text-md'>
                                             Hours
                                          </span>
                                       </button>
                                       {/* <button
                                          className='flex space-x-2 h-8 items-center px-3 py-2 bg-red-500 hover:bg-red-800 rounded-md drop-shadow-md'
                                          onClick={() =>
                                             handleDelete(employee.id)
                                          }
                                       >
                                          <svg
                                             className='fill-white'
                                             xmlns='http://www.w3.org/2000/svg'
                                             x='0px'
                                             y='0px'
                                             width='20'
                                             height='20'
                                             viewBox='0 0 24 24'
                                          >
                                             <path d='M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z'></path>
                                          </svg>
                                          <span className='text-white'>
                                             Delete
                                          </span>
                                       </button> */}
                                    </div>
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
         {open && (
            <EditEmployee
               open={open}
               employee={employee}
               onClose={handleClose}
               onSaveEmployee={handleSaveEmployee}
            />
         )}
         {openService && !isLoadingEmployee && (
            <EditEmployeeService
               open={openService}
               employee={employee}
               onClose={handleCloseService}
               onUpdate={handleUpdateEmployeeService}
            />
         )}
         {openHour && (
            <EditEmployeeHour
               open={openHour}
               employee_id={employee_id}
               onClose={handleCloseHour}
            />
         )}
         {openAdd && (
            <AddEmployee
               open={openAdd}
               onClose={handleCloseAdd}
               onSave={handleSaveAdd}
            />
         )}
      </div>
   );
};
export default Employees;

import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const deleteEmployeeHour = async ({employee_id}) => {
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/employee/delete_hour?employee_id=${employee_id}`,
    );
    
    return data;
    };

export function useDeleteEmployeeHour() {
    const { isLoading, mutateAsync } = useMutation(deleteEmployeeHour);
    return { isDeletingEmployeeHour: isLoading, deleteEmployeeHour: mutateAsync };
}

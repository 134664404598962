import React from 'react';
import { useCategories } from '../services/hooks/useCategories';
import { useNavigate } from 'react-router-dom';
const Test = () => {
   const { status, data: categories, error, isFetching } = useCategories();
   let navigate = useNavigate();
   if (status === 'loading') {
      return <div>Loading...</div>;
   }
   if (status === 'error') {
      //jwt error
      if (error.message.includes('Could not verify JWT')){
         navigate('/login');
      }
      return <div>Error: {error.message}</div>;
   }
   if (status === 'isFetching') {
      return <div>Loading...</div>;
   }
   return (
      <div>
         <ul>
            {categories.map((category) => (
               <li key={category.id}>{category.name}</li>
            ))}
         </ul>
      </div>
   );
};

export default Test;

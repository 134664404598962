import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const fetchEmployeeService = async (employee_id) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/employee/services?employee_id=${employee_id}`
   );

   return data;
};

export function useEmployeeService(employee_id) {
   return useQuery(['employee_services', employee_id], () =>
      fetchEmployeeService(employee_id)
   );
}

import authHeader from "../authHeader";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const setCouponAsUsed = async (values) => {
   const { data } = await axios.post(
      process.env.REACT_APP_API_SERVER + "/api/coupon/used",
      values,
      { headers: authHeader() }
   );
   return data;
};

export function useSetCouponAsUsed() {
   const { isLoading, mutate } = useMutation(setCouponAsUsed);
   return { isSetting: isLoading, setCouponAsUsed: mutate };
}

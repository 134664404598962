import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchEmployee = async (employee_id) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/employee?employee_id=${employee_id}`
   );
   console.log(data)
   return data;
};

export function useEmployee(employee_id) {
   return useQuery(["employee_detail",employee_id], () => fetchEmployee(employee_id));
}

import React from 'react';
import { Toaster } from 'react-hot-toast';

const ToasterNotification = () => {
   return (
      <Toaster
         position='top-right'
         reverseOrder={false}
         gutter={8}
         toastOptions={{
            success: {
               style: {
                  color: 'green',
               },
            },
            error: {
               style: {
                  color: 'red',
               },
            },
         }}
      />
   );
};

export default ToasterNotification;

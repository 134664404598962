import React from 'react';
import { AiOutlineMessage } from 'react-icons/ai';

import { BsCheckLg } from 'react-icons/bs';

const All = () => {
   return (
      <div className='w-1/2 mt-10'>
         <div className='flex'>
            <div className='flex flex-col items-center mr-4'>
               <div>
                  <div className='flex items-center justify-center w-10 h-10 border rounded-full'>
                     <AiOutlineMessage className='text-2xl text-green-500' />
                  </div>
               </div>
               <div className='w-px h-full bg-gray-300'></div>
            </div>
            <div className='pb-8 '>
               <p className='mb-2 text-lg font-bold text-gray-600'>
                  New Message
               </p>
               <p className='text-gray-700'>
                  Garrett sent a message: Y a day ago
               </p>
            </div>
         </div>
         <div className='flex'>
            <div className='flex flex-col items-center mr-4'>
               <div>
                  <div className='flex items-center justify-center w-10 h-10 border rounded-full'>
                     <AiOutlineMessage className='text-2xl text-green-500' />
                  </div>
               </div>
               <div className='w-px h-full bg-gray-300'></div>
            </div>
            <div className='pb-8 '>
               <p className='mb-2 text-lg font-bold text-gray-600'>
                  New Message
               </p>
               <p className='text-gray-700'>
                  Garrett sent a message: Y a day ago
               </p>
            </div>
         </div>
         <div className='flex'>
            <div className='flex flex-col items-center mr-4'>
               <div>
                  <div className='flex items-center justify-center w-10 h-10 border rounded-full'>
                     <AiOutlineMessage className='text-2xl text-green-500' />
                  </div>
               </div>
               <div className='w-px h-full bg-gray-300'></div>
            </div>
            <div className='pb-8 '>
               <p className='mb-2 text-lg font-bold text-gray-600'>
                  New Message
               </p>
               <p className='text-gray-700'>
                  Garrett sent a message: Y a day ago
               </p>
            </div>
         </div>

         <div className='flex'>
            <div className='flex flex-col items-center mr-4'>
               <div>
                  <div className='flex items-center justify-center w-10 h-10 border rounded-full'>
                     <BsCheckLg className='text-2xl text-red-500' />
                  </div>
               </div>
               <div className='w-px h-full bg-gray-300'></div>
            </div>
            <div className='pb-8 '>
               <p className='mb-2 text-lg font-bold text-gray-600'>Check in</p>
               <p className='text-gray-700'>Garrett checked in a day ago</p>
            </div>
         </div>
      </div>
   );
};

export default All;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchEmployee = async (store_id) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/employee/store?store_id=${store_id}`
   );

   return data;
};

export function useEmployees(store_id) {
   return useQuery(["employees",store_id], () => fetchEmployee(store_id));
}

import React from 'react';
import {
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
} from '@material-tailwind/react';
import { useForm, Controller } from 'react-hook-form';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
   CheckBoxComponent,
   RadioButtonComponent,
   ButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-react-dropdowns/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';

const AddEmployee = ({ open, onClose, onSave }) => {
   const defaultValues = {
      first_name: '',
      last_name: '',
      phone: '',
   };

   const {
      handleSubmit,
      control,
      watch,
      formState: { errors },
   } = useForm({ defaultValues });

   //Error Component
   const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>;
   const onSubmit = (data) => onSave(data);

   return (
      <>
         <Dialog open={open} handler={onClose} size={'xs'}>
            <DialogHeader>Add Employee</DialogHeader>
            <DialogBody divider>
               <form onSubmit={handleSubmit(onSubmit)}>
                  <section>
                     <label>First Name</label>
                     {/* include validation with required or other standard HTML validation rules */}
                     <Controller
                        name='first_name'
                        control={control}
                        rules={{ required: true }}
                        defaultValue=''
                        render={({ field }) => (
                           <TextBoxComponent
                              placeholder='Enter your First Name'
                              // floatLabelType="Auto"
                              change={({ value }) => field.onChange(value)}
                              value={field.value}
                           />
                        )}
                     />
                     {errors.first_name && (
                        <Error>This field is required</Error>
                     )}
                  </section>
                  <section>
                     <label>Last Name</label>
                     {/* include validation with required or other standard HTML validation rules */}
                     <Controller
                        name='last_name'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                           <TextBoxComponent
                              placeholder='Enter your Last Name'
                              // floatLabelType="Auto"
                              change={({ value }) => field.onChange(value)}
                              value={field.value}
                           />
                        )}
                     />
                  </section>
                  <section>
                     <label>Phone</label>
                     {/* include validation with required or other standard HTML validation rules */}
                     <Controller
                        name='phone'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                           <TextBoxComponent
                              placeholder='Enter your Phone Number'
                              // floatLabelType="Auto"
                              change={({ value }) => field.onChange(value)}
                              value={field.value}
                           />
                        )}
                     />
                  </section>
                  <section>
                     <label>Email</label>
                     {/* include validation with required or other standard HTML validation rules */}
                     <Controller
                        name='email'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                           <TextBoxComponent
                              placeholder='Enter your Email'
                              // floatLabelType="Auto"
                              change={({ value }) => field.onChange(value)}
                              value={field.value}
                           />
                        )}
                     />
                  </section>

                  <div className='flex justify-between mt-5'>
                     <ButtonComponent
                        cssClass='e-primary'
                        onClick={() => onClose()}
                     >
                        Cancel
                     </ButtonComponent>
                     <ButtonComponent type='submit' cssClass='e-success'>
                        Save&nbsp;&nbsp;
                     </ButtonComponent>
                  </div>
               </form>
            </DialogBody>
            <DialogFooter>
               <div></div>
            </DialogFooter>
         </Dialog>
      </>
   );
};

export default AddEmployee;

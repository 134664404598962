import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchCoupons = async (eventId) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/coupon/event?event_id=${eventId}`
   );

   return data;
};

export function useCoupons(eventId) {
   return useQuery(["coupons",eventId], () => fetchCoupons(eventId));
}

import React, { useState } from 'react';
import { Header } from '../components';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { useAuth } from '../contexts/AuthProvider';
import moment from 'moment-timezone';
import { NumericTextBoxComponent,TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Selection,
   Inject,
   Edit,
   Toolbar,
} from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import ReactiveButton from 'reactive-button';

const Payment = () => {
   let { userInfo } = useAuth();
   const [date, setDate] = React.useState(new Date());
   const [showDialog, setShowDialog] = React.useState(false);
   const [cashState, setCashState] = useState('idle');
   const [creditState, setCreditState] = useState('idle');
   const [tipState, setTipState] = useState('idle');
   const [isProcessingCredit, setIsProcessingCredit] = useState(false);
   const [isProcessingCash, setIsProcessingCash] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');
   const [tipAmount, setTipAmount] = useState(0);
   const [amount, setAmount] = useState(0);

   let gridInstance;
   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl:
         process.env.REACT_APP_API_SERVER +
         `/api/order/daily?store_id=${userInfo.storeId}&date=${moment(
            date
         ).format('YYYY-MM-DD')}`,
      url:
         process.env.REACT_APP_API_SERVER +
         `/api/order/daily?store_id=${userInfo.storeId}&date=${moment(
            date
         ).format('YYYY-MM-DD')}`,
   });
   const editSettings = {
      allowEditing: false,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: 'Top',
   };
   function timeTemplate(arg) {
      return (
         <div>
            <div>{moment(arg.StartTime).format('YYYY-MM-DD HH:mm')}</div>
         </div>
      );
   }
   function customerTemplate(arg) {
      return (
         <div>
            <div>
               {arg.first_name}&nbsp;{arg.last_name}
            </div>
         </div>
      );
   }
   function handleClick() {
      setShowDialog(true);
   }
   function handleCloseDialog() {
      setShowDialog(false);
   }
   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header category='Page' title='客户付款' />
         <div id='target' className='col-lg-12 target-element'></div>
         <div>
            <button
               className='bg-grey-light hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center'
               onClick={handleClick}
            >
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z'
                  />
               </svg>

               <span> &nbsp;付款</span>
            </button>
         </div>

         <div className='w-3/4 mt-10'>
            <GridComponent
               id='bill'
               dataSource={dataManager}
               editSettings={editSettings}
               ref={(g) => {
                  gridInstance = g;
               }}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field='StartTime'
                     headerText='时间'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                     template={timeTemplate}
                  />
                  <ColumnDirective
                     field='name'
                     headerText='服务'
                     width='130'
                     textAlign='Right'
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='phone_number'
                     headerText='电话号码'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='first_name'
                     headerText='客户'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                     template={customerTemplate}
                  />
                  <ColumnDirective
                     field='total_payment'
                     headerText='金额'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                  />
               </ColumnsDirective>
               <Inject services={[Selection]} />
            </GridComponent>
         </div>
         <DialogComponent
            id='dialog'
            header='付款'
            target='#target'
            width={'520px'}
            showCloseIcon={true}
            visible={showDialog}
            close={handleCloseDialog}
         >
            <div className='modal-footer flex flex-row items-center justify-end p-4 border-t border-gray-200 rounded-b-md'>
               
               <div>
                    金额： <NumericTextBoxComponent
                           format='c2'
                           value={amount}
                           min={0}
                           onChange={(e) => {setAmount(e.value)}}
                        ></NumericTextBoxComponent>
                </div>
                <div>
                    小费：<div className='w-32'>
                        <NumericTextBoxComponent
                           format='c2'
                           value={tipAmount}
                           min={0}
                           onChange={(e) => {setTipAmount(e.value)}}
                        ></NumericTextBoxComponent>
                     </div>
                </div>
               <div>
                  {errorMessage !== '' && (
                     <div className='text-red-500'>{errorMessage}</div>
                  )}
               </div>
               <div className='mr-5'>
                  <ReactiveButton
                     id='credit'
                     buttonState={creditState}
                     idleText='信用卡'
                     loadingText='处理中'
                     successText='完成'
                     color='primary'
                     // onClick={processCredit}
                     // disabled={
                     //    isProcessingCredit ||
                     //    isProcessingCash ||
                     //    isPaid ||
                     //    data[0]?.PaymentStatus === 1
                     // }
                  />
               </div>
               <div>
                  <ReactiveButton
                     id='cash'
                     buttonState={cashState}
                     color='teal'
                     idleText='現金'
                     loadingText='处理中'
                     successText='完成'
                     // onClick={processCash}
                     // disabled={
                     //    isProcessingCredit ||
                     //    isProcessingCash 
                     // }
                  />
               </div>
               <div className='ml-5'>
                  <ReactiveButton
                     id='tip'
                     buttonState={tipState}
                     color='blue'
                     idleText='收小费'
                     loadingText='处理中'
                     successText='完成'
                     // onClick={processTip}
                     // disabled={gatewayReferenceNumber === '' || Number(tip) < 1 }
                  />
               </div>
               <div className='ml-5'>
                  <ReactiveButton
                     id='close'
                     buttonState={'idle'}
                     color='red'
                     idleText='关闭'
                     loadingText=''
                     successText=''
                     // onClick={handleClose}
                     // disabled={isProcessingCredit || isProcessingCash}
                  />
               </div>
            </div>
         </DialogComponent>
      </div>
   );
};

export default Payment;

import React from 'react';
import {
   ScheduleComponent,
   Day,
   Week,
   WorkWeek,
   Month,
   Agenda,
   Inject,
   ResourcesDirective,
   ResourceDirective,
   ViewsDirective,
   ViewDirective,
   TimelineViews,
   Resize,
   DragAndDrop,
} from '@syncfusion/ej2-react-schedule';
import { DataManager, UrlAdaptor, Query } from '@syncfusion/ej2-data';

import { FaPeopleArrows, FaRegSmile } from 'react-icons/fa';

import {
   extend,
   closest,
   isNullOrUndefined,
   Internationalization,
   remove,
   removeClass,
} from '@syncfusion/ej2-base';

import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../node_modules/@syncfusion/ej2-schedule/styles/material.css';
import { Header } from '../components';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { useAuth } from '../contexts/AuthProvider';
import { useServices } from '../services/hooks/useServices';
import { useActiveEmployees2 } from '../services/hooks/useActiveEmployees2';
import { useCustomer } from '../services/hooks/useCustomer2';
import { useOrders2 } from '../services/hooks/useOrders2';
import PaymentDialog from '../components/PaymentDialog';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
   AutoCompleteComponent,
   DropDownListComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { useQueryClient } from '@tanstack/react-query';

const Appointment = () => {
   const { userInfo } = useAuth();
   const queryClient = useQueryClient();
   const { isLoading, error, data: services } = useServices(userInfo.storeId);
   const {
      isLoading: isLoading2,
      error: error2,
      data: employees,
   } = useActiveEmployees2(userInfo.storeId);
   const {
      isLoading: isLoading3,
      error: error3,
      data: customers,
   } = useCustomer(userInfo.storeId);
   const {
      isLoading: isLoading4,
      error: error4,
      data: orders,
   } = useOrders2(userInfo.storeId);

   const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false);
   const [eventId, setEventId] = React.useState(null);
   let scheduleObj;
   let menuObj;
   let eventObj;
   let selectedTarget;
   let timer;

   const dataManager = new DataManager({
      url:
         process.env.REACT_APP_API_SERVER +
         '/api/scheduleevents/getData?storeId=' +
         userInfo.storeId,
      crudUrl:
         process.env.REACT_APP_API_SERVER + '/api/scheduleevents/crudActions2',
      adaptor: new UrlAdaptor(),
      // crossDomain: true,
   });

   

   let employeeData = employees?.map((employee) => {
      return {
         ...employee,
         name: employee.first_name + ' ' + employee.last_name,
      };
   });
   let customerData = customers?.map((customer) => {
      return {
         Id: customer.id,
         Text:
            customer.first_name +
            ' ' +
            customer.last_name +
            '-' +
            customer.phone_number,
      };
   });

   const menuItems = [
      // { text: '付款', iconCss: 'e-icons e-plus', id: 'Pay' },
      { text: '预约', iconCss: 'e-icons e-plus', id: 'Add' },
      {
         text: 'New Recurring Event',
         iconCss: 'e-icons e-repeat',
         id: 'AddRecurrence',
      },
      { text: 'Today', iconCss: 'e-icons e-timeline-today', id: 'Today' },
      { text: 'Edit Event', iconCss: 'e-icons e-edit', id: 'Save' },
      {
         text: 'Edit Event',
         id: 'EditRecurrenceEvent',
         iconCss: 'e-icons e-edit',
         items: [
            { text: 'Edit Occurrence', id: 'EditOccurrence' },
            { text: 'Edit Series', id: 'EditSeries' },
         ],
      },
      { text: 'Delete Event', iconCss: 'e-icons e-trash', id: 'Delete' },
      {
         text: 'Delete Event',
         id: 'DeleteRecurrenceEvent',
         iconCss: 'e-icons e-trash',
         items: [
            { text: 'Delete Occurrence', id: 'DeleteOccurrence' },
            { text: 'Delete Series', id: 'DeleteSeries' },
         ],
      },
   ];
   function onMenuItemSelect(args) {
      let selectedMenuItem = args.item.id;
      if (selectedTarget.classList.contains('e-appointment')) {
         eventObj = scheduleObj.getEventDetails(selectedTarget);
         if (eventObj) {
            setEventId(eventObj.id);
         }
      }
      switch (selectedMenuItem) {
         case 'Pay':
            setPaymentDialogOpen(true);
            break;
         case 'Today':
            scheduleObj.selectedDate = new Date();
            break;
         case 'Add':
         case 'AddRecurrence':
            let selectedCells = scheduleObj.getSelectedElements();
            let activeCellsData = scheduleObj.getCellDetails(
               selectedCells.length > 0 ? selectedCells : selectedTarget
            );
            if (selectedMenuItem === 'Add') {
               scheduleObj.openEditor(activeCellsData, 'Add');
            } else {
               scheduleObj.openEditor(activeCellsData, 'Add', null, 1);
            }
            break;
         case 'Save':
         case 'EditOccurrence':
         case 'EditSeries':
            if (selectedMenuItem === 'EditSeries') {
               eventObj = new DataManager(scheduleObj.eventsData).executeLocal(
                  new Query().where(
                     scheduleObj.eventFields.id,
                     'equal',
                     eventObj[scheduleObj.eventFields.recurrenceID]
                  )
               )[0];
            }
            scheduleObj.openEditor(eventObj, selectedMenuItem);
            break;
         case 'Delete':
            scheduleObj.deleteEvent(eventObj);
            break;
         case 'DeleteOccurrence':
         case 'DeleteSeries':
            scheduleObj.deleteEvent(eventObj, selectedMenuItem);
            break;
         default:
            break;
      }
   }
   function onContextMenuBeforeOpen(args) {
      let newEventElement = document.querySelector('.e-new-event');
      if (newEventElement) {
         remove(newEventElement);
         removeClass(
            [document.querySelector('.e-selected-cell')],
            'e-selected-cell'
         );
      }
      let targetElement = args.event.target;
      if (closest(targetElement, '.e-contextmenu')) {
         return;
      }
      selectedTarget = closest(
         targetElement,
         '.e-appointment,.e-work-cells,' +
            '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells'
      );
      if (isNullOrUndefined(selectedTarget)) {
         args.cancel = true;
         return;
      }
      if (selectedTarget.classList.contains('e-appointment')) {
         // selected appointment;
         eventObj = scheduleObj.getEventDetails(selectedTarget);
         if (eventObj.RecurrenceRule) {
            menuObj.showItems(
               ['EditRecurrenceEvent', 'DeleteRecurrenceEvent'],
               true
            );
            menuObj.hideItems(
               ['Add', 'AddRecurrence', 'Today', 'Save', 'Delete'],
               true
            );
         } else {
            menuObj.showItems(['Save', 'Delete'], true);
            menuObj.hideItems(
               [
                  'Add',
                  'AddRecurrence',
                  'Today',
                  'EditRecurrenceEvent',
                  'DeleteRecurrenceEvent',
                  'Delete', //TODO: add delete function in the context menu, pass wrong id
               ],
               true
            );
         }
         return;
      }
      menuObj.hideItems(
         ['Save', 'Delete', 'EditRecurrenceEvent', 'DeleteRecurrenceEvent'],
         true
      );
      menuObj.showItems(['Add', 'Today'], true);
   }
   if (isLoading || isLoading2 || isLoading3 || isLoading4) {
      return <div>Loading...</div>;
   }
   if (error || error2 || error3 || error4) {
      console.log(error, error2, error3, error4);
      return <div>Error</div>;
   }

   // refresh data every 3 minutes
   const onCreated = async () => {
      timer =  setInterval(() => {
         scheduleObj?.refreshEvents();
      }, 180000);
   };
   const onDestroyed = () => {
      clearInterval(timer);
   };
   function findEmployeeById(id) {
      return employees?.find((employee) => employee.id === id);
   }
   function findServiceById(id) {
      return services.find((service) => service.id === id);
   }
   function findCustomerById(id) {
      return customers.find((customer) => customer.id === id);
   }
   function findOrderById(id) {
      return orders.find((order) => order.id === id);
   }
   const getTimeString = (value) => {
      var instance = new Internationalization(); //ej2-base
      return instance.formatDate(value, { skeleton: 'hm' });
   };
   function eventTemplate(args) {
      let employee = findEmployeeById(args.EmployeeID);
      let customer = findCustomerById(args.CustomerID);
      let service = findServiceById(args.ServiceID);
      let order = findOrderById(args.OrderID);

      let paymentStatus = '未付';
      if (
         args.PaymentStatus === 1 ||
         (order &&
            typeof order.is_checked_out !== 'undefined' &&
            order.is_checked_out)
      ) {
         paymentStatus = '已付';
      }

      return (
         <div>
            <div className='flex flex-row '>
               {order?.is_checked_in && (
                  <span className='text-yellow-700 text-xl font-bold mr-3'>
                     <FaRegSmile />
                  </span>
               )}
               {customer?.first_name ? customer.first_name : ''}{' '}
               {customer?.last_name ? customer.last_name : ''}
               <span>({customer?.num_of_visits})</span>
               {args.Status === 1 && (
                  <span className='text-white ml-3'> (已确认)</span>
               )}
               {args.Status === null && (
                  <span className='text-white ml-3'> (未确认)</span>
               )}
               {service?.num_of_customer > 1 && (
                  <span className='text-white ml-3'>双人</span>
               )}
               &nbsp; &nbsp;{paymentStatus}
            </div>
            <div className='time'>
               {getTimeString(args.StartTime)} - {getTimeString(args.EndTime)}
            </div>
            <div className='service'>
               {findServiceById(args.ServiceID)?.name}
            </div>
            <div className='description'>{args.Description}</div>
            <div className='employee'>
               {employee.first_name + ' ' + employee.last_name}
            </div>
         </div>
      );
   }

   function handlePaymentDialogClose() {
      setPaymentDialogOpen(false);
   }
   function onActionBegin(args) {
      //TODO: search customer by phone number
      if (args.requestType === 'eventCreate') {
         if (args.data[0].CustomerInfo) {
            let searchValue = args.data[0].CustomerInfo;
            let phone = searchValue.split('-')[1];
            args.data[0].CustomerID =
               customerData[
                  customerData.findIndex((x) => x.Text.split('-')[1] === phone)
               ].Id;
         }
         args.data[0].StoreID = userInfo.storeId;
      } else if (args.requestType === 'eventChange') {
         let searchValue = args.data.CustomerInfo;
         let phone = searchValue.split('-')[1];

         if (args.data.CustomerInfo) {
            args.data.CustomerID =
               customerData[
                  customerData.findIndex((x) => x.Text.split('-')[1] === phone)
               ].Id;
         }
      } else if (args.requestType === 'eventRemove') {
      }

      queryClient.invalidateQueries('appointments');
      // console.log(args.data.CustomerID);
   }
   function editorWindowTemplate(props) {
      return (
         <table className='custom-event-editor' style={{ width: '100%' }}>
            <tbody>
               <tr>
                  <td className='e-textlabel'>Service</td>
                  <td colSpan={4}>
                     <DropDownListComponent
                        id='ServiceID'
                        dataSource={services}
                        placeholder='Select a Service'
                        fields={{ text: 'name', value: 'id' }}
                        data-name='ServiceID'
                        className='e-field'
                        value={props.ServiceID}
                     ></DropDownListComponent>
                  </td>
               </tr>
               <tr>
                  <td className='e-textlabel'>Customer</td>
                  <td colSpan={4}>
                     <AutoCompleteComponent
                        id='CustomerID'
                        dataSource={customerData}
                        placeholder='Select a Customer'
                        fields={{ value: 'Text' }}
                        data-name='CustomerInfo'
                        value={props.CustomerInfo}
                        className='e-field'
                     ></AutoCompleteComponent>
                  </td>
               </tr>
               <tr>
                  <td className='e-textlabel'>Employee</td>
                  <td colSpan={4}>
                     <DropDownListComponent
                        id='EmployeeID'
                        dataSource={employeeData}
                        placeholder='Select a employee'
                        fields={{ text: 'name', value: 'id' }}
                        data-name='EmployeeID'
                        className='e-field'
                        value={props.EmployeeID}
                     ></DropDownListComponent>
                  </td>
               </tr>
               <tr>
                  <td className='e-textlabel'>From</td>
                  <td colSpan={4}>
                     <DateTimePickerComponent
                        id='StartTime'
                        data-name='StartTime'
                        value={new Date(props.startTime || props.StartTime)}
                        className='e-field'
                     ></DateTimePickerComponent>
                  </td>
               </tr>
               <tr>
                  <td className='e-textlabel'>To</td>
                  <td colSpan={4}>
                     <DateTimePickerComponent
                        id='EndTime'
                        data-name='EndTime'
                        value={new Date(props.endTime || props.EndTime)}
                        className='e-field'
                     ></DateTimePickerComponent>
                  </td>
               </tr>

               <tr>
                  <td className='e-textlabel'>Notes</td>
                  <td colSpan={4}>
                     <textarea
                        id='description'
                        className='e-field e-input'
                        name='Description'
                        data-name='Description'
                     />
                     {props.Description}
                  </td>
               </tr>
               <tr>
                  <td className='e-textlabel'>Send Confirmation:</td>
                  <td colSpan={4}>
                     <input
                        type='checkbox'
                        id='SendConfirmation'
                        name='SendConfirmation'
                        className='e-field e-input'
                        data-name='SendConfirmation'
                     />
                  </td>
               </tr>
            </tbody>
         </table>
      );
   }
   return (
      <>
         <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
            <Header category='Page' title='Calendar' />
            <ScheduleComponent
               height='950px'
               ref={(t) => (scheduleObj = t)}
               selectedDate={new Date()}
               startHour='09:00'
               endHour='23:00'
               workDays={[0, 1, 2, 3, 4, 5, 6]}
               workHours={{ highlight: true, start: '09:00', end: '23:00' }}
               eventSettings={{
                  dataSource: dataManager,
                  template: eventTemplate,
               }}
               editorTemplate={editorWindowTemplate}
               actionBegin={onActionBegin}
               showQuickInfo={false}
               group={{ byDate: true, resources: ['Employees'] }}
               created={onCreated.bind(this)}
               destroyed={onDestroyed.bind(this)}
            >
               <ResourcesDirective>
                  <ResourceDirective
                     field='EmployeeID'
                     title='Employee'
                     name='Employees' //used in grouping
                     allowMultiple={false}
                     dataSource={employeeData}
                     textField='name'
                     idField='id'
                     colorField='color'
                  ></ResourceDirective>
               </ResourcesDirective>
               <ViewsDirective>
                  <ViewDirective
                     option='Day'
                     isSelected={true}
                     startHour='09:00'
                     endHour='23:00'
                  />
                  <ViewDirective
                     option='Day'
                     displayName='3 Days'
                     interval={3}
                  />
                  <ViewDirective
                     option='Week'
                     displayName='2 Weeks'
                     interval={2}
                  />
                  <ViewDirective option='Month' />
                  <ViewDirective option='TimelineDay' />
                  <ViewDirective option='TimelineWeek' />
               </ViewsDirective>
               <Inject
                  services={[
                     Day,
                     Week,
                     WorkWeek,
                     Month,
                     Agenda,
                     TimelineViews,
                     Resize,
                     DragAndDrop,
                  ]}
               />
            </ScheduleComponent>
            <ContextMenuComponent
               cssClass='schedule-context-menu'
               ref={(menu) => (menuObj = menu)}
               target='.e-schedule'
               items={menuItems}
               beforeOpen={onContextMenuBeforeOpen.bind(this)}
               select={onMenuItemSelect.bind(this)}
            />
         </div>
         <div id='dialog-target'>
            {eventId && (
               <PaymentDialog
                  open={paymentDialogOpen}
                  eventId={eventId}
                  onClose={handlePaymentDialogClose}
               />
            )}
         </div>
      </>
   );
};

export default Appointment;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchCustomer = async (id,store_id) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/customer/details?id=${id}&store_id=${store_id}`
   );

   return data;
};

export function useCustomer(id,store_id) {
   return useQuery(["customer_detail",id,store_id], () => fetchCustomer(id,store_id));
}

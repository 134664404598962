import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchMessage = async (phone,store_id) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/message/latest?store_id=${store_id}&phone=${phone}`
   );

   return data;
};

export function useLatestMessage(store_id,phone) {
   return useQuery(['message_latest',store_id, phone], () => fetchMessage(store_id,phone));
}

import React, { useEffect } from 'react';
import ChatContent from '../components/Chat/ChatContent';
import CustomerCard from '../components/Chat/CustomerCard';
import { useAuth } from '../contexts/AuthProvider';
import { useMessageCustomer } from '../services/hooks/useMessageCustomer2';
import { getLatestCustomerList, searchCustomerList } from '../services/dataService';

const Conversation = () => {
   const { userInfo } = useAuth();
   const { isLoading, data, error } = useMessageCustomer(userInfo.storeId);
   const [selectedCustomer, setSelectedCustomer] = React.useState({customer_phone: '', first_name: '', last_name: ''});
   const [customerList, setCustomerList] = React.useState([]);
   const [search, setSearch] = React.useState('');
  
   useEffect(() => {
      getLatestCustomerList(userInfo.storeId).then((res) => {
         setCustomerList(res);
         if (selectedCustomer.customer_phone === '') {
            setSelectedCustomer({customer_phone: res[0].customer_phone, first_name: res[0].first_name, last_name: res[0].last_name})
         }
      });
   
   }, [userInfo.storeId])
  
   if (isLoading) {
      return <div>Loading...</div>;
   }
   if (error) {
      console.log(error);
      return <div>Error</div>;
   }
   
   function changeSearch(e) {
      setSearch(e.target.value);
      if (e.target.value === '') {
         getLatestCustomerList(userInfo.storeId).then((res) => {
            setCustomerList(res);
         });
      } else {
         searchCustomerList(userInfo.storeId, e.target.value).then((res) => {
            setCustomerList(res);
         }); 
      }
   }

   return (
      <div className='main flex-1 flex flex-col'>
         <div className='hidden lg:block heading flex-2'>
            <h1 className='text-3xl text-gray-700 mb-4'>Chat</h1>
         </div>

         <div className='flex-1 flex h-full'>
            <div className='sidebar hidden lg:flex w-1/3 flex-2 flex-col pr-6'>
               <div className='search flex-2 pb-6 px-2'>
                  <input
                     type='text'
                     className='outline-none py-2 block w-full bg-transparent border-b-2 border-gray-200'
                     placeholder='Search'
                     value={search}
                     onChange={changeSearch}
                  />
               </div>

               <div className='flex-1 h-full overflow-auto px-2'>
                  {customerList.map((item) => (
                     <CustomerCard {...item} key={item.id} selectCustomer={setSelectedCustomer} />
                  ))}
               </div>
            </div>
           <ChatContent first_name={selectedCustomer.first_name} last_name={selectedCustomer.last_name} phone={selectedCustomer.customer_phone} />
         </div>
      </div>
   );
};

export default Conversation;

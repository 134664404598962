const baseUrl = process.env.REACT_APP_API_SERVER;
// get

export function loginUser({ email, password }) {
   return fetch(baseUrl + `/auth/login`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
   })
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function processTransaction(data) {
   return fetch(baseUrl + `/api/transaction/chargeanywhere`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   }).then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function processTipTransaction(data) {
   return fetch(baseUrl + `/api/transaction/chargeanywhere/tip`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   }).then((res) => res.json())
      .then((data) => {
         return data;
      });
}


export function queryTransaction(uniqueId) {
   return fetch(
      baseUrl + `/api/transaction/chargeanywhere?uniqueId=${uniqueId}`
   )
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function updateTransactionResult(data) {
   return fetch(baseUrl + `/api/transaction/chargeanywhere/update`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   });
}

export function sendMessage(data) {
   return fetch(baseUrl + `/api/message/send`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   });
}

export function getLatestCustomerList(storeId) {
   return fetch(baseUrl + `/api/message/customer?store_id=${storeId}`)
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function searchCustomerList(storeId, keyword) {
   return fetch(baseUrl + `/api/message/customer/search?store_id=${storeId}&text=${keyword}`)
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function getFeedbacks(storeId) {
   return fetch(baseUrl + `/api/feedback?store_id=${storeId}`)
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function getCouponList(event_id) {
   return fetch(baseUrl + `/api/coupon/event?event_id=${event_id}`)
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function updateEmployeeDailySchedule(employee_id) {
   return fetch(baseUrl + `/api/schedule/update_employee_daily_schedule?employee_id=${employee_id}`)
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function checkInOrder(data) {
   return fetch(baseUrl + `/api/order/checkin`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   });
}

export function unCheckInOrder(data) {
   return fetch(baseUrl + `/api/order/uncheckin`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   });
}

export function unCheckOutOrder(data) {
   return fetch(baseUrl + `/api/order/uncheckout`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
   });
}

export function getService(service_id) {
   return fetch(baseUrl + `/api/service/service?service_id=${service_id}`)
   .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function checkOutOrderService(values)
{
   return fetch(baseUrl + `/api/order/checkout`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
   });
}

export function getOrderById(order_id) {
   return fetch(baseUrl + `/api/order?order_id=${order_id}`)
   .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export function addEmployee(values) {
   return fetch(baseUrl + `/api/employee/add`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
   });
}

export function getMemberInfo(member_id) {
   return fetch(baseUrl + `/api/member/${member_id}`)
   .then((res) => res.json())
      .then((data) => {
         return data;
      });
}




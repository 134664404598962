import React from 'react';
import { useCustomer } from '../services/hooks/useCustomer2';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Page,
   Selection,
   Inject,
   Edit,
   Toolbar,
   Sort,
   Filter,
   Search,
} from '@syncfusion/ej2-react-grids';
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import { Header } from '../components';
import { useAuth } from '../contexts/AuthProvider';
import { Typography } from '@material-tailwind/react';

const Customers = () => {
   let navigate = useNavigate();
   let { userInfo } = useAuth();
   const {
      status,
      data: customers,
      error,
      isFetching,
   } = useCustomer(userInfo.storeId);
   if (status === 'loading') {
      return <div>Loading...</div>;
   }
   if (status === 'error') {
      //jwt error
      if (error.message.includes('Could not verify JWT')) {
         navigate('/login');
      }
      return <div>Error: {error.message}</div>;
   }
   if (isFetching) {
      return <div>Loading...</div>;
   }
   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl:
         process.env.REACT_APP_API_SERVER + "/api/customer/syncfusion_update",
      insertUrl:
         process.env.REACT_APP_API_SERVER + `/api/customer/syncfusion_insert?store_id=${userInfo.storeId}`,
      url: process.env.REACT_APP_API_SERVER + `/api/customer/syncfusion?store_id=${userInfo.storeId}`,
   });
   //get random color
   const getRandomColor = () => {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
         color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
   };
   const toolbarOptions = ['Search',"Add", "Edit", "Update", "Cancel"];
   const editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: false,
      newRowPosition: "Top",
   };
   function customerTemplate(props) {
      return (
         <div>
            <span className='inline-flex h-6 w-6 items-center justify-center rounded-full bg-green-500'>
               <span className='text-xs font-medium leading-none text-white'>
                  {props.first_name !== ''
                     ? props.first_name[0].toUpperCase()
                     : ''}
                  {props.last_name !== ''
                     ? props.last_name[0].toUpperCase()
                     : ''}
               </span>
            </span>

            <span className='ml-3'>
               {props.first_name} {props.last_name}
            </span>
         </div>
      );
   }
   function firstSeenTemplate(props) {
      let result = moment(props.created_at).fromNow();
      return (
         <div>
            <span>{result}</span>
         </div>
      );
   }
   function lastSeenTemplate(props) {
      let result;
      props.last_active_at
         ? (result = moment(props.last_active_at).fromNow())
         : (result = '');
      return (
         <div>
            <span>{result}</span>
         </div>
      );
   }
   function tagsTemplate(props) {
      return (
         props.tags === 'blacklist' && (
            <div className='flex gap-2 justify-center items-center text-red-700'>
               <p
                  style={{ background: 'green' }}
                  className='rounded-full h-3 w-3'
               />
               <p>{'blacklist'}</p>
            </div>
         )
      );
   }

   function handleDetailClick(id)
   {
      navigate(`/customer-detail/${id}`);
   }
   function detailTemplate(props)
   {
      return (
         <div>
           <button className="btn btn-primary" onClick={()=>handleDetailClick(props.id)}>Details</button>
         </div>
      )
   }

   
   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header category='Page' title='Customers' />
         <Typography variant='h6' className='mb-5 mr-10'>Total: {customers?.length}</Typography>
         <GridComponent
            dataSource={dataManager}
            editSettings={editSettings}
            toolbar={toolbarOptions}
            enableHover={false}
            allowPaging
            pageSettings={{ pageCount: 5 }}
            allowSorting
         >
            <ColumnsDirective>
               <ColumnDirective
                  field='first_name'
                  headerText='First Name'
                  clipMode='EllipsisWithTooltip'
                  width='100'
                  allowEditing={true}
               />
               <ColumnDirective
                  field='last_name'
                  headerText='Last Name'
                  clipMode='EllipsisWithTooltip'
                  width='80'
                  allowEditing={true}
               />
               <ColumnDirective
                  field='phone_number'
                  headerText='Phone'
                  width='140'
                  allowEditing={true}
               />
               <ColumnDirective
                  field='tags'
                  headerText='Tags'
                  template={tagsTemplate}
                  width='60'
                  allowEditing={true}
               />
               <ColumnDirective
                  field='created_at'
                  headerText='First Seen'
                  template={firstSeenTemplate}
                  width='100'
                  allowEditing={false}
               />
               <ColumnDirective
                  field='last_active_at'
                  headerText='Last Seen'
                  template={lastSeenTemplate}
                  width='100'
                  allowEditing={false}
               />
               <ColumnDirective
                  field='num_of_visits'
                  headerText='Visits'
                  width='80'
                  allowEditing={false}
               />
               <ColumnDirective
                  field='num_of_coupon_redeemed'
                  headerText='Coupons'
                  width='80'
                  allowEditing={false}
               />
               <ColumnDirective
                  headerText = 'Details'
                  template = {detailTemplate}
                  width = '80'
                  allowEditing = {false}
               />
            </ColumnsDirective>
            <Inject
               services={[Page, Selection, Toolbar, Edit, Sort, Filter, Search]}
            />
         </GridComponent>
      </div>
   );
};

export default Customers;

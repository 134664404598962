import {
   ColumnDirective,
   ColumnsDirective,
} from '@syncfusion/ej2-react-charts';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import React from 'react';
import moment from 'moment-timezone';
import { Header } from '../components';
import { useAuth } from '../contexts/AuthProvider';

import { getFeedbacks } from '../services/dataService';

const Feedback = () => {
   let { userInfo } = useAuth();
   const [feedbacks, setFeedbacks] = React.useState([]);

   React.useEffect(() => {
      getFeedbacks(userInfo.storeId).then((data) => {
         setFeedbacks(data);
      });
   }, [userInfo.storeId]);

   function customerTemplate(props) {
      return (
         <div>
            <span className='inline-flex h-6 w-6 items-center justify-center rounded-full bg-green-500'>
               <span className='text-xs font-medium leading-none text-white'>
                  {props.first_name !== ''
                     ? props.first_name[0].toUpperCase()
                     : ''}
                  {props.last_name !== ''
                     ? props.last_name[0].toUpperCase()
                     : ''}
               </span>
            </span>

            <span className='ml-3'>
               {props.first_name} {props.last_name}-{props.customer_phone}
            </span>
         </div>
      );
   }

   function serviceTemplate(props){
        return (
            <div>
               {props.employee_first_name}   {props.name} {moment.utc(props.StartTime).tz('America/New_York').format('MM/DD/YYYY hh:mm A')}
                
            </div>
        )
   }
   function feedbackTemplate(props)
   {
         return (
              <div class="whitespace-normal">
                {props.score} - {props.content}
              </div>
         )
   }

   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header category='Page' title='客户反馈' />
         <GridComponent
            dataSource={feedbacks}
            toolbar={[]}
            enableHover={false}
            allowPaging
            pageSettings={{ pageCount: 20 }}
            allowSorting
         >
            <ColumnsDirective>
               <ColumnDirective field='id' headerText='ID' width='15' />
               <ColumnDirective
                  field='customer'
                  headerText='Name'
                  clipMode='EllipsisWithTooltip'
                  template={customerTemplate}
                  width='100'
               />
               <ColumnDirective
                  field='content'
                  headerText='Content'
                  template={feedbackTemplate}
                  width='120'
               />
                <ColumnDirective
                    field="service"
                    headerText="Service"
                    template={serviceTemplate}
                    width="100"
                />
            </ColumnsDirective>
         </GridComponent>
      </div>
   );
};

export default Feedback;

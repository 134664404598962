import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchEmployeeHour = async (employee_id) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/employee/hours?employee_id=${employee_id}`
   );

   return data;
};

export function useEmployeeHour(employee_id) {
   return useQuery(["employee_hours",employee_id], () => fetchEmployeeHour(employee_id));
}

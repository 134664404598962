import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import React from 'react';
import { Header, CustomerChart, SalesChart } from '../components';

const Report = () => {
  let headerText = [
     { text: '客户统计' },
     { text: '销售统计' },
    //  { text: 'Forms' },
     // { text: 'Appointments' },
     // { text: 'Photos' },
     // { text: 'Invoices' },
  ];

  return (
     <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category='Page' title='报表统计' />
        <TabComponent heightAdjustMode='Auto' id='defaultTab'>
           <TabItemsDirective>
              <TabItemDirective header={headerText[0]} content={CustomerChart} />
              <TabItemDirective
                 header={headerText[1]}
                 content={SalesChart}
              />
           </TabItemsDirective>
        </TabComponent>
     </div>
  );
};

export default Report;

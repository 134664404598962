import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
   CheckBoxComponent,
   RadioButtonComponent,
   ButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { useAuth } from '../contexts/AuthProvider';
import { useCustomer } from '../services/hooks/useCustomer2';
import moment from 'moment-timezone';
import { useAddMember } from '../services/hooks/useAddMember';
import { useQueryClient } from '@tanstack/react-query';

const AddMemberDialog = ({ open, onClose}) => {
   const { userInfo } = useAuth();
   const queryClient = useQueryClient();
   const { isLoading, data } = useCustomer(userInfo.storeId);
   const [autoObj, setAutoObj] = React.useState(null);
   const [customer_id, setCustomerId] = React.useState(null);
   const [first_name, setFirstName] = React.useState(null);
   const [last_name, setLastName] = React.useState(null);
   const [phone_number, setPhoneNumber] = React.useState(null);
   const [expiration_date, setExpirationDate] = React.useState(
      moment().add(1, 'year').format('YYYY-MM-DD')
   );
   const [member_type_id, setMemberTypeId] = React.useState(1);
   const [error, setError] = React.useState('');
   const [message, setMessage] = React.useState('');
   const { isLoading: isSaving, addMember } = useAddMember();
   const [btnEnabled, setBtnEnabled] = React.useState(false);

   if (isLoading) {
      return <div>Loading...</div>;
   }

   let customerData = data?.map((customer) => {
      return {
         Id: customer.id,
         Text:
            customer.first_name +
            ' ' +
            customer.last_name +
            '-' +
            customer.phone_number,
      };
   });
   let memberTypeData = [
      { id: 1, name: 'Gold' },
      { id: 2, name: 'Diamond' },
   ];
   let fields = { text: 'name', value: 'id' };
   
   function handleSelect(args) {
      let customer =
         data[customerData.findIndex((x) => x.Text === args.itemData.Text)];
      setCustomerId(customer.id);
      setFirstName(customer.first_name);
      setLastName(customer.last_name);
      setPhoneNumber(customer.phone_number);
      setBtnEnabled(true);
   }
   function handleAddMember(){
    addMember({
        customer_id,
        member_type_id,
        expiration_date,
        store_id:userInfo.storeId
    }, {
        onSuccess: () => {
            setMessage('添加成功');
            setBtnEnabled(false);
            queryClient.invalidateQueries('members');
        },
        onError: (error) => {
            setError(error);
        },
    })
   }
   return (
      <DialogComponent
         id='dialog'
         header='添加会员'
         target='#target'
         width={'437px'}
         showCloseIcon={true}
         visible={open}
         close={() => onClose()}
      >
         <div>
            <AutoCompleteComponent
               id='memberId'
               dataSource={customerData}
               placeholder='客户搜索'
               fields={{ value: 'Text' }}
               className='px-4 py-2 w-60'
               select={handleSelect}
               ref={(autoObj) => setAutoObj(autoObj)}
            ></AutoCompleteComponent>
         </div>

         <div>
            <div className='flex flex-row mt-4'>
               <div className='w-1/2'>
                  First Name：
                  <input
                     type='text'
                     className='px-4 py-2 w-60'
                     placeholder='First Name'
                     value={first_name}
                     onChange={(e) => setFirstName(e.target.value)}
                  />
               </div>
               <div className='w-1/2'>
                  Last Name：
                  <input
                     type='text'
                     className='px-4 py-2 w-60'
                     placeholder='Last Name'
                     value={last_name}
                     onChange={(e) => setLastName(e.target.value)}
                  />
               </div>
            </div>
         </div>
         <div className='flex flex-row mt-4'>
            <div className='w-1/2'>
               Phone：{' '}
               <input
                  type='text'
                  className='px-4 py-2 w-60'
                  placeholder='Phone Number'
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
               />
            </div>
            <div className='w-1/2'>
               Expiration：{' '}
               <input
                  type='text'
                  className='px-4 py-2 w-60'
                  placeholder='Expiration Date'
                  value={expiration_date}
                  onChange={(e) => setExpirationDate(e.target.value)}
               />
            </div>
         </div>
         <div className='flex flex-row mt-4 items-baseline'>
            <div>Member Type:</div>
            <div className='w-32 ml-5'>
            <DropDownListComponent
               id='tip_payment'
               dataSource={memberTypeData}
               fields={fields}
               placeholder='Select a type'
               value={member_type_id}
               onChange={(e) => {
                  setMemberTypeId(e.value);
               }}
               popupHeight='100px'
            />
            </div>
         </div>
         <div className='mt-4'>
                  {/* show error */}
                  {error && <div className='text-red-500'>{error}</div>}
                    {/* show message */}
                    {message && <div className='text-green-500'>{message}</div>}
               </div>
               <div className='mt-10'>
                 
                  <button
                     className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
                     onClick={() => handleAddMember()}
                     disabled={isSaving||phone_number==null||!btnEnabled}
                  >
                        添加会员
                  </button>
               </div>
      </DialogComponent>
   );
};

export default AddMemberDialog;

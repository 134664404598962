import {
   GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Selection,
   Inject,
   Edit,
   Toolbar,
} from '@syncfusion/ej2-react-grids';
import React from 'react';
import { Header } from '../components';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import moment from 'moment-timezone';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useAuth } from '../contexts/AuthProvider';

const EmployeeSchedule = () => {
   const { userInfo } = useAuth();
   const [date, setDate] = React.useState(moment.tz(userInfo.time_zone).format('YYYY-MM-DD'));
   const dataManager = new DataManager({
      adaptor: new UrlAdaptor(),
      updateUrl:
         process.env.REACT_APP_API_SERVER + '/api/employee/update_schedule',
      url:
         process.env.REACT_APP_API_SERVER +
         `/api/employee/schedule?schedule_date=${date}&store_id=${userInfo.storeId}`,
   });
   const toolbarOptions = ['Edit', 'Update', 'Cancel'];
   const editSettings = {
      allowEditing: true,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: 'Top',
   };
   function handleDateChange(args) {
      setDate(moment.tz(args.value, 'America/New_York').format('YYYY-MM-DD'));
   }
   function scheduleTemplate(props) {
      
      let d = new Date(props.schedule_date);
      // convert to UTC
      d = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);

      return (
         <div>
            <div>
               {moment(d).format('YYYY-MM-DD')}
            </div>
         </div>
      );
   }
   function startTimeTemplate(props) {
      return (
         <div>
            <div>{moment(props.start_time, 'hh:mm:ss').format('HH:mm')}</div>
         </div>
      );
   }
   function endTimeTemplate(props) {
      return (
         <div>
            <div>{moment(props.end_time, 'hh:mm:ss').format('HH:mm')}</div>
         </div>
      );
   }
   return (
      <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
         <Header category='Page' title='员工排班' />
         <div className='w-48 mb-10'>
            <DatePickerComponent
               value={date}
               onChange={handleDateChange}
            ></DatePickerComponent>
         </div>
         <div className='w-1/2'>
            <GridComponent
               id='schedule'
               dataSource={dataManager}
               editSettings={editSettings}
               toolbar={toolbarOptions}
            >
               <ColumnsDirective>
                  <ColumnDirective
                     field='id'
                     headerText='ID'
                     width='100'
                     textAlign='Right'
                     isPrimaryKey={true}
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='name'
                     headerText='姓名'
                     width='100'
                     textAlign='Right'
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='schedule_date'
                     headerText='日期'
                     width='100'
                     textAlign='Right'
                     template={scheduleTemplate}
                     allowEditing={false}
                  />
                  <ColumnDirective
                     field='start_time'
                     headerText='开始时间'
                     width='100'
                     textAlign='Right'
                     template={startTimeTemplate}
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field='end_time'
                     headerText='结束时间'
                     width='100'
                     textAlign='Right'
                     template={endTimeTemplate}
                     allowEditing={true}
                  />
                  <ColumnDirective
                     field='is_available'
                     headerText='上班'
                     width='100'
                     textAlign='Right'
                     allowEditing={true}
                  />
               </ColumnsDirective>
               <Inject services={[Selection, Toolbar, Edit]} />
            </GridComponent>
         </div>
      </div>
   );
};

export default EmployeeSchedule;

import React from 'react';
import moment from 'moment-timezone';
import { useAuth } from '../../contexts/AuthProvider';

const { useLatestMessage } = require('../../services/hooks/useLatestMessage2');

const CustomerCard = ({ first_name, last_name, customer_phone,selectCustomer }) => {
   const { userInfo } = useAuth();
   const {
      isLoading,
      error,
      data: messages,
   } = useLatestMessage(customer_phone, userInfo.storeId);
   let initial = '';
   let message = '';
   let updated_at = '';
   if (messages && messages.length > 0) {
      message = messages[0].content;
      // updated_at = moment.utc(messages[0].updated_at).tz('America/New_York');
      updated_at = moment
         .duration(moment().diff(moment(messages[0].created_at)))
         .humanize();
   }
   if (last_name !== '') {
      initial =
         first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase();
   } else {
      initial = first_name.charAt(0).toUpperCase();
   }
   if (isLoading) {
      return <div>Loading...</div>;
   }
   if (error) {
      console.log(error);
      return <div>Error...</div>;
   }
   function handleClick(){
      selectCustomer({customer_phone,first_name,last_name})
   }
   return (
      <div className='entry cursor-pointer transform hover:scale-105 duration-300 transition-transform bg-white mb-4 rounded p-4 flex shadow-md' onClick={()=>handleClick()}>
         <div className='flex-2'>
            <div className='w-12 h-12 relative'>
               <span className='inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500'>
                  <span className='font-medium leading-none text-white'>
                     {initial}
                  </span>
               </span>
               <span className='absolute w-4 h-4 bg-green-400 rounded-full right-0 bottom-0 border-2 border-white'></span>
            </div>
         </div>
         <div className='flex-1 px-2'>
            <div className='truncate w-32'>
               <span className='text-gray-800'>
                  {first_name}&nbsp;{last_name}
               </span>
            </div>
            <div>
               <small className='text-gray-600'>{message}</small>
            </div>
         </div>
         <div className='flex-2 text-right'>
            <div>
               <small className='text-gray-500'>{updated_at} ago</small>
            </div>
            {/* <div>
               <small className='text-xs bg-red-500 text-white rounded-full h-6 w-6 leading-6 text-center inline-block'>
                  23
               </small>
            </div> */}
         </div>
      </div>
   );
};

export default CustomerCard;

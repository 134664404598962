import React from 'react';
import moment from 'moment';

import {
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
   Checkbox,
   Select,
   Option,
} from '@material-tailwind/react';
import { useForm, Controller } from 'react-hook-form';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
   CheckBoxComponent,
   RadioButtonComponent,
   ButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-react-dropdowns/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import { useAddEmployeeHour } from '../services/hooks/useAddEmployeeHour';
import { useQueryClient } from '@tanstack/react-query';
import { useEmployeeHour } from '../services/hooks/useEmployeeHour';
import { useDeleteEmployeeHour } from '../services/hooks/useDeleteEmployeeHour';
import LoadingComponent from './LoadingComponent';
import { updateEmployeeDailySchedule } from '../services/dataService';

const EditEmployeeHour = ({ employee_id, open, onClose }) => {
   const queryClient = useQueryClient();
   const BusinessHour = [
      '08:00 AM',
      '09:00 AM',
      '09:30 AM',
      '10:00 AM',
      '11:00 AM',
      '12:00 PM',
      '01:00 PM',
      '02:00 PM',
      '03:00 PM',
      '04:00 PM',
      '05:00 PM',
      '06:00 PM',
      '07:00 PM',
      '08:00 PM',
      '09:00 PM',
      '09:30 PM',
      '10:00 PM',
   ];
   const { isAddingEmployeeHour,  addEmployeeHour } =
      useAddEmployeeHour();
   const { isDeletingEmployeeHour, deleteEmployeeHour } =
      useDeleteEmployeeHour();
   const { isLoading, data: employee_hour } = useEmployeeHour(employee_id);

   const businessHours = ['09:00 AM', '09:00 PM'];

   const {
      handleSubmit,
      control,
      watch,
      formState: { errors },
   } = useForm();
   const onSubmit = (data) => {
      let value = [];
      // Language: javascript
      for (let i = 0; i < 7; i++) {
         //get key from data which starts with i
         if (data[i]) {
            value.push({
               day: i,
               start_time: data[i + '-from'],
               end_time: data[i + '-to'],
               employee_id: employee_id,
            });
         }
      }
      deleteEmployeeHour(
         { employee_id: employee_id },
         {
            onSuccess: () => {
               addEmployeeHour(value, {
                  onSuccess: () => {
                     // updateEmployeeDailySchedule(employee_id).then(() => {                     
                     //    queryClient.invalidateQueries('employee_hour');
                     //    return onClose();
                     // });
                     queryClient.invalidateQueries('employee_hours');
                     return onClose();
                  },
                  onError: (error) => {
                     console.log(error);
                  },
               });
            },
         }
      );
   };
   if (isLoading || isAddingEmployeeHour || isDeletingEmployeeHour) {
      return <div>Loading...</div>;
   }
   return (
      <Dialog open={open} size={'md'}>
         <DialogHeader>Edit Services</DialogHeader>
         <DialogBody divider>
            <LoadingComponent
               open={
                  isLoading || isAddingEmployeeHour || isDeletingEmployeeHour
               }
            />
            <form onSubmit={handleSubmit(onSubmit)}>
               <section className='flex flex-row items-center'>
                  <Controller
                     name='1'
                     control={control}
                     defaultValue={
                        employee_hour?.find((item) => item.day === 1)
                           ? true
                           : false
                     }
                     render={({ field }) => (
                        <CheckBoxComponent
                           label='Monday'
                           onChange={(e) => field.onChange(e.target.checked)}
                           checked={field.value}
                        />
                     )}
                  />
                  <span>&nbsp;&nbsp;</span>
                  <Controller
                     name='1-from'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 1)
                           ? moment(
                                employee_hour.find((x) => x.day === 1)
                                   .start_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[0]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
                  <span className='mx-5'>-</span>
                  <Controller
                     name='1-to'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 1)
                           ? moment(
                                employee_hour.find((x) => x.day === 1)
                                   .end_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[1]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
               </section>
               <section className='flex flex-row items-center'>
                  <Controller
                     name='2'
                     control={control}
                     defaultValue={
                        employee_hour?.find((item) => item.day === 2)
                           ? true
                           : false
                     }
                     render={({ field }) => (
                        <CheckBoxComponent
                           label='Tuesday'
                           onChange={(e) => field.onChange(e.target.checked)}
                           checked={field.value}
                        />
                     )}
                  />
                  <span>&nbsp;&nbsp;</span>
                  <Controller
                     name='2-from'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 2)
                           ? moment(
                                employee_hour.find((x) => x.day === 2)
                                   .start_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[0]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
                  <span className='mx-5'>-</span>
                  <Controller
                     name='2-to'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 2)
                           ? moment(
                                employee_hour.find((x) => x.day === 2)
                                   .end_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[1]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
               </section>
               <section className='flex flex-row items-center'>
                  <Controller
                     name='3'
                     control={control}
                     defaultValue={
                        employee_hour?.find((item) => item.day === 3)
                           ? true
                           : false
                     }
                     render={({ field }) => (
                        <CheckBoxComponent
                           label='Wednesday'
                           onChange={(e) => field.onChange(e.target.checked)}
                           checked={field.value}
                        />
                     )}
                  />
                  <span>&nbsp;&nbsp;</span>
                  <Controller
                     name='3-from'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 3)
                           ? moment(
                                employee_hour.find((x) => x.day === 3)
                                   .start_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[0]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
                  <span className='mx-5'>-</span>
                  <Controller
                     name='3-to'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 3)
                           ? moment(
                                employee_hour.find((x) => x.day === 3)
                                   .end_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[1]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
               </section>
               <section className='flex flex-row items-center'>
                  <Controller
                     name='4'
                     control={control}
                     defaultValue={
                        employee_hour?.find((item) => item.day === 4)
                           ? true
                           : false
                     }
                     render={({ field }) => (
                        <CheckBoxComponent
                           label='Thursday'
                           onChange={(e) => field.onChange(e.target.checked)}
                           checked={field.value}
                        />
                     )}
                  />
                  <span>&nbsp;&nbsp;</span>
                  <Controller
                     name='4-from'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 4)
                           ? moment(
                                employee_hour.find((x) => x.day === 4)
                                   .start_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[0]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
                  <span className='mx-5'>-</span>
                  <Controller
                     name='4-to'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 4)
                           ? moment(
                                employee_hour.find((x) => x.day === 4)
                                   .end_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[1]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
               </section>
               <section className='flex flex-row items-center'>
                  <Controller
                     name='5'
                     control={control}
                     defaultValue={
                        employee_hour?.find((item) => item.day === 5)
                           ? true
                           : false
                     }
                     render={({ field }) => (
                        <CheckBoxComponent
                           label='Friday'
                           onChange={(e) => field.onChange(e.target.checked)}
                           checked={field.value}
                        />
                     )}
                  />
                  <span>&nbsp;&nbsp;</span>
                  <Controller
                     name='5-from'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 5)
                           ? moment(
                                employee_hour.find((x) => x.day === 5)
                                   .start_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[0]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
                  <span className='mx-5'>-</span>
                  <Controller
                     name='5-to'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 5)
                           ? moment(
                                employee_hour.find((x) => x.day === 5)
                                   .end_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[1]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
               </section>
               <section className='flex flex-row items-center'>
                  <Controller
                     name='6'
                     control={control}
                     defaultValue={
                        employee_hour?.find((item) => item.day === 6)
                           ? true
                           : false
                     }
                     render={({ field }) => (
                        <CheckBoxComponent
                           label='Saturday'
                           onChange={(e) => field.onChange(e.target.checked)}
                           checked={field.value}
                        />
                     )}
                  />
                  <span>&nbsp;&nbsp;</span>
                  <Controller
                     name='6-from'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 6)
                           ? moment(
                                employee_hour.find((x) => x.day === 6)
                                   .start_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[0]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
                  <span className='mx-5'>-</span>
                  <Controller
                     name='6-to'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 6)
                           ? moment(
                                employee_hour.find((x) => x.day === 6)
                                   .end_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[1]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
               </section>
               <section className='flex flex-row items-center'>
                  <Controller
                     name='0'
                     control={control}
                     defaultValue={
                        employee_hour?.find((item) => item.day === 0)
                           ? true
                           : false
                     }
                     render={({ field }) => (
                        <CheckBoxComponent
                           label='Sunday'
                           onChange={(e) => field.onChange(e.target.checked)}
                           checked={field.value}
                        />
                     )}
                  />
                  <span>&nbsp;&nbsp;</span>
                  <Controller
                     name='0-from'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 0)
                           ? moment(
                                employee_hour.find((x) => x.day === 0)
                                   .start_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[0]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
                  <span className='mx-5'>-</span>
                  <Controller
                     name='0-to'
                     control={control}
                     defaultValue={
                        employee_hour.find((x) => x.day === 0)
                           ? moment(
                                employee_hour.find((x) => x.day === 0)
                                   .end_time,
                                'HH:mm:ss'
                             ).format('hh:mm A')
                           : businessHours[1]
                     }
                     render={({ field }) => (
                        <DropDownListComponent
                           dataSource={BusinessHour}
                           select={({ itemData }) => {
                              field.onChange(itemData.value);
                           }}
                           value={field.value}
                        />
                     )}
                  />
               </section>
               <div className='flex justify-evenly mt-5'>
                  <ButtonComponent
                     cssclassName='e-primary'
                     onClick={() => onClose()}
                  >
                     Cancel
                  </ButtonComponent>
                  <ButtonComponent type='submit' cssclassName='e-success'>
                     Save&nbsp;&nbsp;
                  </ButtonComponent>
               </div>
            </form>
         </DialogBody>
         <DialogFooter>
            <div></div>
         </DialogFooter>
      </Dialog>
   );
};

export default EditEmployeeHour;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchDailyOrders = async (storeId,date) => {
   const {data} = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/order/daily?store_id=${storeId}&date=${date}`
   );

   return data;
};

export function useDailyOrders(storeId, date) {
   return useQuery(["daily_orders",storeId, date], () => fetchDailyOrders(storeId, date));
}

import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';

export function formatMoney(number) {
   if (number) {
      return parseFloat(number).toLocaleString('en-US', {
         style: 'currency',
         currency: 'USD',
      });
   } else {
      return '$0.00';
   }
}

export function getTimeString2(value) {
   var instance = new Internationalization(); //ej2-base
   return instance.formatDate(value, { skeleton: 'hm' });
}

export function getTimeString(value) {
   return moment(value).format('h:mm A');
}

import React from 'react';
import {
   ChartComponent,
   SeriesCollectionDirective,
   SeriesDirective,
   Inject,
   LineSeries,
   DateTime,
   Legend,
   Tooltip,
} from '@syncfusion/ej2-react-charts';
import { useAuth } from '../../contexts/AuthProvider';
import { useSalesReport } from '../../services/hooks/useSalesReport';

export const LinePrimaryXAxis = {
   valueType: 'DateTime',
   labelFormat: 'y/M',
   intervalType: 'Months',
   edgeLabelPlacement: 'Shift',
   majorGridLines: { width: 0 },
   background: 'white',
};
export const LinePrimaryYAxis = {
   labelFormat: '{value}',
   rangePadding: 'None',
   minimum: 0,
   maximum: 50000,
   interval: 5000,
   lineStyle: { width: 0 },
   majorTickLines: { width: 0 },
   minorTickLines: { width: 0 },
};
const SalesChart = () => {
   let { userInfo } = useAuth();
   const { isLoading, data } = useSalesReport(userInfo.storeId);
   const lineChartData = [[]];
   if (isLoading) {
      return <div>Loading...</div>;
   }

   for (let i = 0; i < data.length; i++) {
      lineChartData[0].push({
         x: data[i].report_date,
         y: data[i].total,
      });
   }
   const lineCustomSeries = [
      {
         dataSource: lineChartData[0],
         xName: 'x',
         yName: 'y',
         name: '销售额',
         width: '2',
         marker: { visible: true, width: 10, height: 10 },
         type: 'Line',
      },
   ];
   return (
      <div className='mt-10'>
         {' '}
         <ChartComponent
            id='sales-chart'
            height='420px'
            width='60%'
            primaryXAxis={LinePrimaryXAxis}
            primaryYAxis={LinePrimaryYAxis}
            chartArea={{ border: { width: 0 } }}
            tooltip={{ enable: true }}
            background={'#fff'}
            legendSettings={{ background: 'white' }}
         >
            <Inject services={[LineSeries, DateTime, Legend, Tooltip]} />
            <SeriesCollectionDirective>
               {/* eslint-disable-next-line react/jsx-props-no-spreading */}
               {lineCustomSeries.map((item, index) => (
                  <SeriesDirective key={index} {...item} />
               ))}
            </SeriesCollectionDirective>
         </ChartComponent>
      </div>
   );
};

export default SalesChart;

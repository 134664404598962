import {
   AiOutlineCalendar,
   AiOutlineShoppingCart,
   AiOutlineAreaChart,
   AiOutlineBarChart,
   AiOutlineStock,
} from 'react-icons/ai';
import {
   FiShoppingBag,
   FiEdit,
   FiPieChart,
   FiBarChart,
   FiCreditCard,
   FiStar,
   FiShoppingCart,
} from 'react-icons/fi';
import {
   BsKanban,
   BsBarChart,
   BsBoxSeam,
   BsCurrencyDollar,
   BsShield,
   BsChatLeft,
} from 'react-icons/bs';
import { BiColorFill } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import {VscFeedback} from 'react-icons/vsc';
import { HiOutlineRefresh } from 'react-icons/hi';
import { TiTick } from 'react-icons/ti';
import { GiLouvrePyramid } from 'react-icons/gi';
import { GrLocation } from 'react-icons/gr';

export const links = [
   // {
   //    title: 'Dashboard',
   //    links: [
   //       {
   //          name: 'ecommerce',
   //          link: 'ecommerce',
   //          icon: <FiShoppingBag />,
   //       },
   //    ],
   // },

   {
      title: 'Pages',
      links: [
         {
            name: '预约管理',
            link: 'appointment',
            icon: <AiOutlineShoppingCart />,
         },
         {
            name: '会员管理',
            link: 'members',
            icon: <FiStar />,
         },
         {
            name: '客户管理',
            link: 'customers',
            icon: <RiContactsLine />,
         },
         {
            name: '服务管理',
            link: 'service',
            icon: <BsBoxSeam />,
         },
         {
            name: '员工管理',
            link: 'employees',
            icon: <MdOutlineSupervisorAccount />,
         },
         {
            name: '员工排班',
            link: 'employee_schedule',
            icon: <BsKanban />,
         },
         {
            name: '客户交流',
            link: 'conversation',
            icon: <BsChatLeft />,
         },
         {
            name: '客户反馈',
            link: 'feedback',
            icon: <VscFeedback />,
         },
         {
            name: '账单管理',
            link: 'bill',
            icon: <BsCurrencyDollar />,
         },
         {
            name: '报表统计',
            link: 'report',
            icon: <FiPieChart />,
         }
      ],
   }
];

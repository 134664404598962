import React from 'react';
import {
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
} from '@material-tailwind/react';
import { useCustomerByPhone } from '../services/hooks/useCustomerByPhone';
import { useAuth } from '../contexts/AuthProvider';

const AddCustomerDialog = ({ open, onClose,onCancel }) => {
   const {userInfo} = useAuth();
   const [phone, setPhone] = React.useState('');
   const [firstName, setFirstName] = React.useState('');
   const [lastName, setLastName] = React.useState('');
   const [email, setEmail] = React.useState('');
   const {data:customer} = useCustomerByPhone(phone, userInfo.storeId);
   const [error, setError] = React.useState('');
   function handleConfirm() {
      setError('');
      let value = {
         phone,
         firstName,
         lastName,
         email,
         storeId: userInfo.storeId,
      };

      // check if customer exists
      if (customer.length > 0) {
         setError('Customer already exists');
      }else
      {  
         setFirstName('');
         setLastName('');
         setEmail('');
         setPhone('');
         setError('');
         onClose(value);
      }
      
   }
   function handleCancel(){
      setError('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setError('');
      onCancel();
   }
   return (
      <Dialog open={open} handler={onClose} size={'xs'}>
         <DialogHeader>添加客户</DialogHeader>
         <DialogBody divider>
            <div className='mt-10 sm:mt-0'>
               <div className='mt-5 md:mt-0 md:col-span-2'>
                     <form action='#' method='POST'>
                        <div className='shadow overflow-hidden sm:rounded-md'>
                           <div className='px-4 py-5 bg-white sm:p-6'>
                              <div className='grid grid-cols-6 gap-6'>
                              <div className='col-span-6 sm:col-span-4'>
                                    <label
                                       htmlFor='phone'
                                       className='block text-sm font-medium text-gray-700'
                                    >
                                       Phone
                                    </label>
                                    <input
                                       type='text'
                                       name='phone'
                                       id='phone'
                                       className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                       value={phone}
                                       onChange={(e) => setPhone(e.target.value)}
                                    />
                                 </div>

                                 <div className='col-span-6 sm:col-span-3'>
                                    <label
                                       htmlFor='first_name'
                                       className='block text-sm font-medium text-gray-700'
                                    >
                                       First name
                                    </label>
                                    <input
                                       type='text'
                                       name='first_name'
                                       id='first_name'
                                       autoComplete='given-name'
                                       className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                       value={firstName}
                                       onChange={(e) =>  setFirstName(e.target.value)}
                                    />
                                 </div>

                                 <div className='col-span-6 sm:col-span-3'>
                                    <label
                                       htmlFor='last_name'
                                       className='block text-sm font-medium text-gray-700'
                                    >
                                       Last name
                                    </label>
                                    <input
                                       type='text'
                                       name='last_name'
                                       id='last_name'
                                       autoComplete='family-name'
                                       className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                       value={lastName}
                                       onChange={(e) => setLastName(e.target.value)}
                                    />
                                 </div>

                                 <div className='col-span-6 sm:col-span-4'>
                                    <label
                                       htmlFor='email_address'
                                       className='block text-sm font-medium text-gray-700'
                                    >
                                       Email address
                                    </label>
                                    <input
                                       type='text'
                                       name='email_address'
                                       id='email_address'
                                       autoComplete='email'
                                       className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                    />
                                 </div>
                              </div>
                           </div>
                           
                        </div>
                     </form>
                  </div>
                  <div className='text-red-500'>{error}</div>
            </div>
            
         </DialogBody>
         <DialogFooter>
            <Button
               variant='text'
               color='red'
               onClick={handleCancel}
               className='mr-1'
            >
               <span>Cancel</span>
            </Button>
            <Button variant='gradient' color='green' onClick={handleConfirm}>
               <span>Confirm</span>
            </Button>
         </DialogFooter>
      </Dialog>
   );
};

export default AddCustomerDialog;

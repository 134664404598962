import React from 'react';
import moment from 'moment-timezone';

const CustomerMessage = ({ initial, message, time }) => {

    let updated_at = moment
         .duration(moment.utc().diff(moment(time)))
         .humanize();
   return (
      <div className='message mb-4 flex'>
         <div className='flex-2'>
            <div className='w-12 h-12 relative'>
               <span className='inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500'>
                  <span className='font-medium leading-none text-white'>
                     {initial}
                  </span>
               </span>
               <span className='absolute w-4 h-4 bg-gray-400 rounded-full right-0 bottom-0 border-2 border-white'></span>
            </div>
         </div>
         <div className='flex-1 px-2'>
            <div className='inline-block bg-gray-300 rounded-full p-2 px-6 text-gray-700'>
               <span>{message}</span>
            </div>
            <div className='pl-4'>
               <small className='text-gray-500'>{updated_at} ago</small>
            </div>
         </div>
      </div>
   );
};

export default CustomerMessage;

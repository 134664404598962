import axios from 'axios';

import { useMutation } from '@tanstack/react-query';

const updateEmployee = async (employee) => {
    console.log('updateEmployee', employee);
    const { data } = await axios.post(
        process.env.REACT_APP_API_SERVER + `/api/employee/update`,
        employee
    );
    
    return data;
    };

export function useUpdateEmployee() {
    const { isLoading, mutateAsync } = useMutation(updateEmployee);
    return { isUpdatingEmployee: isLoading, updateEmployee: mutateAsync };
}

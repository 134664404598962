import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchCustomerHistory = async (customerId) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/customer/history?id=${customerId}`
   );

   return data;
};

export function useCustomerHistory(customerId) {
   return useQuery(["customer_history",customerId], () => fetchCustomerHistory(customerId));
}

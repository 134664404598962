import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchDailyGrouponTotal = async (storeId,date) => {
   const {data} = await axios.get(
      process.env.REACT_APP_API_SERVER + `/api/order/daily/groupon?store_id=${storeId}&date=${date}`
   );

   return data;
};

export function useDailyOrderGrouponTotal(storeId,date) {
   return useQuery(["order_groupon_total",storeId,date], () => fetchDailyGrouponTotal(storeId,date));
}

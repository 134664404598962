import React from 'react';
import moment from "moment-timezone";

const SystemMessage = ({ message, time }) => {
   let updated_at = moment
         .duration(moment().diff(moment(time)))
         .humanize();
   return (
      <div className='message me mb-4 flex text-right flex-wrap w-50'>
         <div className='flex-1 px-2'>
            <div className='inline-block bg-blue-600 rounded-full p-2 px-6 text-white'>
               <span>{message}</span>
            </div>
            <div className='pr-4'>
               <small className='text-gray-500'>{updated_at} ago</small>
            </div>
         </div>
      </div>
   );
};

export default SystemMessage;

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import ReactiveButton from 'reactive-button';
import { useAppointment } from '../services/hooks/useAppointmentDetail';
import { Internationalization } from '@syncfusion/ej2-base';
import { formatMoney, getTimeString } from '../services/utils';
import { v4 as uuidv4 } from 'uuid';
import {
   processTipTransaction,
   processTransaction,
   queryTransaction,
   updateTransactionResult,
} from '../services/dataService';
import { useAuth } from '../contexts/AuthProvider';
import { getCouponList } from '../services/dataService';
import Select from 'react-select';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query, WebApiAdaptor } from '@syncfusion/ej2-data';
import { useCoupons } from '../services/hooks/useCoupons';
import { useSetCouponAsUsed } from '../services/hooks/useSetCouponAsUsed';

const PaymentDialog = ({ open, onClose, eventId }) => {
   const { userInfo } = useAuth();
   const { setCouponAsUsed} = useSetCouponAsUsed();

   const [isProcessingCredit, setIsProcessingCredit] = useState(false);
   const [isProcessingCash, setIsProcessingCash] = useState(false);
   const [isPaid, setIsPaid] = useState(false);
   const [cashState, setCashState] = useState('idle');
   const [creditState, setCreditState] = useState('idle');
   const [tipState, setTipState] = useState('idle');
   const [uuid, setUuid] = useState(uuidv4());
   const [paymentStatusText, setPaymentStatusText] = useState('未付');
   const [errorMessage, setErrorMessage] = useState('');

   const [gatewayReferenceNumber, setGatewayReferenceNumber] = useState('');
   const [approvalCode, setApprovalCode] = useState('');
   const [authorizedAmount, setAuthorizedAmount] = useState(0);
   const [tip, setTip] = useState(0);
   const [discount, setDiscount] = useState(0);
   const [couponDiscount, setCouponDiscount] = useState(0);
   const [isCashTip, setIsCashTip] = useState(false);
   const [showTip, setShowTip] = useState(false);
   const [balance, setBalance] = useState(0);

   const { isLoading, data, error } = useAppointment(eventId);
   const { data:couponData } = useCoupons(eventId);

   const [selectedCoupon, setSelectedCoupon] = useState(null);

   
   function handleClose() {
      setIsPaid(false);
      setCashState('idle');
      setCreditState('idle');
      setIsProcessingCash(false);
      setIsProcessingCredit(false);
      setErrorMessage('');
      setPaymentStatusText('未付');
      setTip(0);
      setApprovalCode('');
      setAuthorizedAmount(0);
      setGatewayReferenceNumber('');
      setDiscount(0);
      setCouponDiscount(0);
      setSelectedCoupon(null);
      setShowTip(false);
      onClose();
   }

   const processCredit = async () => {
      let u = uuidv4();
      setUuid(u);
      setErrorMessage('');
      setCreditState('loading');
      setIsProcessingCredit(true);
      let tdata = {
         transactionType: 6, //authorize
         tenderType: 2, //credit
         saleAmount: data[0]?.service.price - discount - couponDiscount,
         serviceFeeLabel: 'Tax and Fee',
         serviceFeeAmount: (data[0]?.service.price - discount- couponDiscount) * 0.04,
         clerkNumber: userInfo.id,
         appointmentId: eventId,
         uniqueId: u,
      };
      processTransaction(tdata)
         .then((res) => {
            if (res.message === 'APPROVED') {
               setCreditState('success');
               setIsProcessingCredit(false);
               setIsPaid(true);
               setPaymentStatusText('已付');
               data[0].PaymentStatus = 1;
               setGatewayReferenceNumber(res.data.GatewayReferenceNumber);
               setApprovalCode(res.data.ApprovalCode);
               setAuthorizedAmount(res.data.AuthorizedAmount);
               setShowTip(true);
               setIsCashTip(false);
                //check if coupon is used
                if (selectedCoupon) {
                  setCouponAsUsed({coupon_code:selectedCoupon.coupon_code, event_id:eventId});
               }
            } else {
               setErrorMessage(
                  'Failed to process transaction with error: ' + res.message
               );
               setIsProcessingCredit(false);
               setIsPaid(false);
               setCreditState('success');
            }
         })
         .catch((err) => {
            console.log(err);
            setCreditState('error');
            setIsProcessingCredit(false);
         });
   };

   const processCash = () => {
      setErrorMessage('');
      setCashState('loading');
      setIsProcessingCash(true);
      // send an HTTP request
      let u = uuidv4();
      setUuid(u);
      let tdata = {
         transactionType: 1,
         tenderType: 5, //cash
         saleAmount: data[0]?.service.price - discount - couponDiscount,
         serviceFeeLabel: 'Tax and Fee',
         serviceFeeAmount: 0,
         clerkNumber: userInfo.id,
         appointmentId: eventId,
         uniqueId: u,
      };
      processTransaction(tdata)
         .then((res) => {

            if (res.message === 'APPROVED') {
               setCashState('success');
               setIsProcessingCash(false);
               setIsPaid(true);
               data[0].PaymentStatus = 1;
               //check if coupon is used
               if (selectedCoupon) {
                  setCouponAsUsed({coupon_code:selectedCoupon.coupon_code, event_id:eventId});
               }
            } else {
               setErrorMessage(
                  'Failed to process transaction with error: ' + res.message
               );
               setIsProcessingCash(false);
               setIsPaid(false);
               setCashState('success');
            }
         })
         .catch((err) => {
            console.log(err);
            setCashState('error');
            setIsProcessingCash(false);
         });
   };

   const processTip = () => {
      let u = uuidv4();
      setUuid(u);
      let tdata = {
         uniqueId: u,
         tenderType: 2, //credit
         saleAmount: authorizedAmount,
         tipAmount: tip,
         originalGatewayReferenceNumber: gatewayReferenceNumber,
         originalApprovalCode: approvalCode,
      };
      processTipTransaction(tdata).then((res) => {
         if (res.message === 'APPROVED') {
            setTipState('success');
            setGatewayReferenceNumber('');
            setApprovalCode('');
            setErrorMessage('小费已成功收取');
            setIsPaid(true);
            data[0].PaymentStatus = 1;
         } else {
            setErrorMessage(
               'Failed to process transaction with error: ' + res.message
            );
         }
      });
   };
   function handleCouponChange(e) {
      setSelectedCoupon(e.value);
      let coupon = couponData.find((c) => c.coupon_code === e.value);
      if (coupon) {
         setCouponDiscount(Number(coupon.discount));
         setSelectedCoupon(coupon);
      }
   }
   function couponTemplate(data) {
      return(
         <div>${data.discount} - {data.coupon_code}</div>
      )
   }
   if (isLoading) {
      return <div>Loading...</div>;
   }
   if (error) {
      return <div>Error: {error.message}</div>;
   }
   return (
      <DialogComponent
         width='550px'
         isModal={true}
         target='#dialog-target'
         visible={open}
         close={onClose}
      >
         <div className='modal-dialog relative w-auto pointer-events-none'>
            <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
               <div className='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
                  <h5
                     className='text-xl font-medium leading-normal text-gray-800'
                     id='customer_info'
                  >
                     {data[0]?.customer.first_name}{' '}
                     {data[0]?.customer.last_name} -{' '}
                     {data[0]?.customer.phone_number}
                  </h5>
                  <button
                     type='button'
                     className='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                     data-bs-dismiss='modal'
                     aria-label='Close'
                  ></button>
               </div>
               <div className='modal-body relative p-4'>
                  <div>{data[0]?.service.name}</div>
                  <div>
                     {getTimeString(data[0]?.StartTime)} -{' '}
                     {getTimeString(data[0]?.EndTime)}
                  </div>
                  <div>价格：{formatMoney(data[0]?.service.price)}</div>
                  <div>
                     折扣：&nbsp;$
                     <input
                        className='w-20'
                        type='number'
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                     />
                  </div>
                  <div className='flex flex-row items-baseline'>
                     <span>折扣码:</span>
                     <div className='w-60 ml-2'>
                        <DropDownListComponent
                           id='coupons'
                           dataSource={couponData}
                           sortOrder='Ascending'
                           fields={{ text: 'discount', value: 'coupon_code' }}
                           placeholder='Select a coupon'
                           popupHeight='220px'
                           value={selectedCoupon}
                           itemTemplate={couponTemplate}
                           valueTemplate={couponTemplate}
                           change={
                             handleCouponChange
                           }
                        />
                     </div>
                  </div>
                  <div>
                     总额：{formatMoney(data[0]?.service.price - discount-couponDiscount)}
                  </div>
                  {gatewayReferenceNumber !== '' && (
                     <div className='flex flex-row'>
                        <span>小费：</span>
                        <input
                           className='w-20'
                           type='number'
                           value={tip}
                           onChange={(e) => setTip(e.target.value)}
                        />
                     </div>
                  )}
                  {data[0]?.PaymentStatus === 1 && (
                     <div className='text-green-500'>已付</div>
                  )}
                  {data[0]?.PaymentStatus !== 1 && (
                     <div className='text-red-500'>未付</div>
                  )}
                  {/* <div className='flex flex-row'>
                     {gatewayReferenceNumber} {approvalCode} {authorizedAmount}
                  </div> */}
               </div>
               {errorMessage !== '' && (
                  <div className='text-red-500'>{errorMessage}</div>
               )}

               <div className='modal-footer flex flex-row items-center justify-end p-4 border-t border-gray-200 rounded-b-md'>
                  <div className='mr-5'>
                     <ReactiveButton
                        id='credit'
                        buttonState={creditState}
                        idleText='信用卡'
                        loadingText='处理中'
                        successText='完成'
                        color='primary'
                        onClick={processCredit}
                        disabled={
                           isProcessingCredit ||
                           isProcessingCash ||
                           isPaid ||
                           data[0]?.PaymentStatus === 1
                        }
                     />
                  </div>
                  <div>
                     <ReactiveButton
                        id='cash'
                        buttonState={cashState}
                        color='teal'
                        idleText='現金'
                        loadingText='处理中'
                        successText='完成'
                        onClick={processCash}
                        disabled={
                           isProcessingCredit ||
                           isProcessingCash ||
                           isPaid ||
                           data[0]?.PaymentStatus === 1
                        }
                     />
                  </div>
                  <div className='ml-5'>
                     <ReactiveButton
                        id='tip'
                        buttonState={tipState}
                        color='blue'
                        idleText='收小费'
                        loadingText='处理中'
                        successText='完成'
                        onClick={processTip}
                        disabled={gatewayReferenceNumber === '' || Number(tip) < 1 }
                     />
                  </div>
                  <div className='ml-5'>
                     <ReactiveButton
                        id='close'
                        buttonState={'idle'}
                        color='red'
                        idleText='关闭'
                        loadingText=''
                        successText=''
                        onClick={handleClose}
                        disabled={isProcessingCredit || isProcessingCash}
                     />
                  </div>
               </div>
            </div>
         </div>
      </DialogComponent>
   );
};

export default PaymentDialog;
